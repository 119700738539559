import Style from './index.module.css';
import { Alert } from 'react-bootstrap';
import { Toast } from './types';
import { IcebergAlert } from '@tradesolution/iceberg-ui-react';

interface Props {
  toasts: Toast[];
  handleCloseToast: any;
  onCancelRequest?: () => void;
}

const Toaster = (props: Props) => {

  return (
    <div className={Style.toastContainer}>
      {props.toasts.map((t, index) => {
        return (
          <IcebergAlert
            key={`alert-${t.type}-${index}`}
            variant={t.type as any}
            onClose={() => props.handleCloseToast(index)}
            dismissible={t.dismissible}
          >
            {t.type === 'danger' && Array.isArray(t.message) ? (
              <div>
                {t.message.map((x: { key: string; errors: string[] }, index: number) => (
                  <div key={`${x.key}-${index}`}>
                    <span>
                      <strong>{x.key}</strong>
                    </span>
                    <ul>
                      {x.errors.map(y => (
                        <li key={y}>{y}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            ) : (
              <span>{t.message as string || ''}</span>
            )}
          </IcebergAlert>
        );
      })}
    </div>
  )
};
export default Toaster;