import { useState, useEffect } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { debounce } from 'lodash';
import { TsKunde } from 'api/SupernovaApi/models';
import SupernovaApi from 'api/SupernovaApi';
import './index.scss'

interface Props {
  selected?: TsKunde;
  onChange: (kunde: TsKunde) => void;
}

const TsKundeTypeahead = (props: Props) => {
  const [query, setQuery] = useState('');
  const [tsKunder, setTsKunder] = useState<TsKunde[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTsKunder, setSelectedTsKunder] = useState<TsKunde[]>([]);

  const searchTsKunder = async () => {
    setIsLoading(true);
    const result = await SupernovaApi.searchTsKundeByName(query);
    setTsKunder(result);
    setIsLoading(false);
  };

  useEffect(() => {
    if (query) {
      searchTsKunder();
    }
  }, [query]);

  const onChangeHandler = (e: TsKunde[]) => {
    if (!e || e.length === 0) {
      props.onChange(undefined);
      setSelectedTsKunder([]);
    } else {
      props.onChange(e[0]);
      setSelectedTsKunder(e);
    }
  };

  useEffect(() => {
    if (props.selected) {
      setSelectedTsKunder([
        {
          id: props.selected.id,
          name: props.selected.name,
        },
      ]);
    }
  }, [props.selected]);


  return (
    <AsyncTypeahead
      id="tsKundeTypeahead"
      labelKey="name"
      clearButton
      minLength={3}
      delay={100}
      isLoading={isLoading}
      options={tsKunder}
      searchText="Søker"
      placeholder="Søk etter tskunde"
      promptText="Skriv for å søke"
      emptyLabel="Fant ingen treff"
      useCache={false}
      maxResults={15}
      onChange={(e: any) => onChangeHandler(e)}
      onSearch={debounce(q => setQuery(q), 300)}
      selected={selectedTsKunder}
      filterBy={() => true}
    />
  );
}

export default TsKundeTypeahead;
