import { IconButton, IconName } from '@tradesolution/iceberg-ui-react';
import React, { useState } from 'react';
import { ButtonProps } from 'react-bootstrap';

interface Props extends ButtonProps {
    icon: IconName;
}

const ConfirmButton = (props: Props) => {
  const [timesPressed, setTimesPressed] = useState(0);
  const [children, setChildren] = useState(props.children);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (timesPressed === 0) {
      setChildren('Sikker?');
      setTimesPressed(z => z + 1);
    } else {
      props.onClick(e);
      setChildren(props.children);
      setTimesPressed(0);
    }
  }

  return (
    <IconButton
      {...props}
      onClick={handleClick}      
      icon={props.icon}>
      {children}
    </IconButton>
  );
};

export default ConfirmButton;
