import SupernovaApi from "api/SupernovaApi";
import { AuditLogEntry } from "api/SupernovaApi/models";
import CommonLoader from "components/CommonLoader";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";

interface Props {
    personalSubscriberId: string;
    reload: boolean;
}

const PersonalSubscriberAuditLog = (props: Props) => {
    const pageSize = 40;
    const [page, setPage] = useState(1);

    const [logEntries, setLogEntries] = useState<AuditLogEntry[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean | null>(null);
    const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);

    useEffect(() => {
        const load = async () => {
            setIsLoading(page === 1);
            setIsFetchingMore(page > 1);
            try {
                const s = await SupernovaApi.profile.getAuditLog(props.personalSubscriberId, pageSize * page);
                if (page === 1) {
                    setLogEntries(s);
                } else {
                    setLogEntries(prev => [...prev, ...s.slice(prev.length)])
                }
                setHasMore(s.length >= (pageSize * page));
            } catch (error) {
                console.error('Feil ved innlasting av meldinger:', error);
            } finally {
                setIsLoading(false);
                setIsFetchingMore(false);
            }
        };
        load();
    }, [props.personalSubscriberId, page, props.reload]);

    return (
        <>
            {isLoading ? null : (
                <>
                    {logEntries.length === 0 && (<p>Ingen meldinger sendt!</p>)}
                    {logEntries.length > 0 && (
                        <Row>
                            <Col>
                                <Table className="tsTable" hover>
                                    <thead>
                                        <tr>
                                            <th>Hendelse</th>
                                            <th>Tidspunkt</th>
                                            <th>Utført av</th>
                                            <th>Innhold</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logEntries.map(a => <tr>
                                            <td>{a.event}</td>
                                            <td>{format(a.timestamp, 'dd.MM.yyyy HH:mm:ss')}</td>
                                            <td>{a.username}</td>
                                            <td>{a.content ? Object.entries(a.content).map(
                                                ([key, value]) => <div><b>{key}</b>: {value}</div>
                                            ) : ""}</td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    )}
                </>
            )}

            <Row className="p-3">
                <Col style={{ textAlign: 'center' }}>
                    {isLoading && !isFetchingMore && (
                        <CommonLoader text="Henter..." />
                    )}
                    {isFetchingMore && (
                        <CommonLoader text="Henter mer..."/>
                    )}
                    {!isLoading && !isFetchingMore && hasMore && (
                        <button type="button" className="btn btn-outline-primary"
                            onClick={() => setPage(page + 1)}>Last flere</button>
                    )}
                </Col>
            </Row>
        </>
    )

}

export default PersonalSubscriberAuditLog;