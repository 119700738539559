import { IconButton } from "@tradesolution/iceberg-ui-react";
import SupernovaApi, { ApiResult } from "api/SupernovaApi";
import { EventType, EventTypeUpdateCommand } from "api/SupernovaApi/models";
import ApiFeedback from "components/ApiFeedback";
import TjenestePicker from "components/TjenestePicker";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import useForm from "utils/hooks/useForm";

interface Props {
    eventType: EventType;
    onClose: () => void;
}

const EditModal = (props: Props) => {

    const mapToCommand = (eventType: EventType): EventTypeUpdateCommand => {
        return {
            id: eventType.id,
            eventTypeName: eventType.eventTypeName,
            displayName: eventType.displayName,
            description: eventType.description,
            payload: eventType.payload,
            source: eventType.source,
            distributionState: eventType.distributionState,
            notAvailableForSourceSubscriptions: eventType.notAvailableForSourceSubscriptions,
            recommendedForDelayedEmailDelivery: eventType.recommendedForDelayedEmailDelivery,
            recommendedForImidiateEmailDelivery: eventType.recommendedForImidiateEmailDelivery,
            recommendedForSmsDelivery: eventType.recommendedForSmsDelivery,
        };
    };

    const defaultValues: EventTypeUpdateCommand = {
        id: '',
        eventTypeName: '',
        displayName: '',
        description: '',
        payload: '',
        source: '',
        distributionState: 0,
        notAvailableForSourceSubscriptions: false,
        recommendedForDelayedEmailDelivery: false,
        recommendedForImidiateEmailDelivery: false,
        recommendedForSmsDelivery: false,
    }

    const validate = (values: EventTypeUpdateCommand) => {
        const errors: any = {};
        if (!values.source) errors.source = "Tjeneste må velges";
        if (!values.eventTypeName) errors.eventTypeName = "Navn må fylles ut";
        if (!values.displayName) errors.displayName = "Visningsnavn må fylles ut";
        return errors;
    };

    const [apiResult, setApiResult] = useState<ApiResult<EventType> | undefined>(undefined);

    const handleSubmit = async (command: EventTypeUpdateCommand) => {
        const result = await SupernovaApi.eventTypes.updateEventType(command);
        setApiResult(result);
        return result;
    };

    const { values, handleChange, resetForm, errors, submitDisabled } = useForm<EventTypeUpdateCommand, ApiResult<EventType>>(mapToCommand(props.eventType) ?? defaultValues, validate, handleSubmit);

    const handleClose = () => {
        // reset state og gi beskjed til parent om at modal er lukket
        resetForm(defaultValues);
        setApiResult(undefined);
        props.onClose();
    };

    const show = !!props.eventType;

    const handleDelete = async (id: string) => {
        await SupernovaApi.eventTypes.deleteEventType(id);
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Endre event type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Tjeneste</Form.Label>
                        <TjenestePicker
                            selectedSource={values.source}
                            onChange={e => handleChange("source", e)}
                            isInvalid={!!errors?.source}
                        />
                        <Form.Control.Feedback type="invalid">{errors?.source}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Navn</Form.Label>
                        <Form.Control
                            type="text"
                            value={values.eventTypeName}
                            onChange={e => handleChange("eventTypeName", e.target.value)}
                            isInvalid={!!errors?.eventTypeName}
                        />
                        <Form.Control.Feedback type="invalid">{errors?.eventTypeName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Visningsnavn</Form.Label>
                        <Form.Control
                            type="text"
                            value={values.displayName}
                            onChange={e => handleChange("displayName", e.target.value)}
                            isInvalid={!!errors?.displayName}
                        />
                        <Form.Control.Feedback type="invalid">{errors?.displayName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Beskrivelse</Form.Label>
                        <Form.Control as="textarea" type="text" value={values.description} onChange={e => handleChange("description", e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Eksempel payload - Json format</Form.Label>
                        <Form.Control as="textarea" type="text" value={values.payload} onChange={e => handleChange("payload", e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Tilgjenglighetsnivå</Form.Label>
                        <Form.Select value={values.distributionState} onChange={e => handleChange("distributionState", e.target.value)}>
                            <option value="0">Kun internt - bare Tradesolution systemer og brukere får lov å se hendelser</option>
                            <option value="1">Offentlig - alle som vil får se alle hendelser</option>
                            <option value="2">Begrenset - bare de som har lov får se hendelser</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" label="Anbefalt varsel" checked={!values.notAvailableForSourceSubscriptions} onChange={e => handleChange("notAvailableForSourceSubscriptions", values.notAvailableForSourceSubscriptions)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Anbefalt leveringsmåte (standard for nye abonnenter)</Form.Label>
                        <Form.Check label="Oppsamlingsepost" checked={values.recommendedForDelayedEmailDelivery} onChange={() => handleChange("recommendedForDelayedEmailDelivery", !values.recommendedForDelayedEmailDelivery)} />
                        <Form.Check label="Epost umiddelbart" checked={values.recommendedForImidiateEmailDelivery} onChange={() => handleChange("recommendedForImidiateEmailDelivery", !values.recommendedForImidiateEmailDelivery)} />
                        <Form.Check label="Sms" checked={values.recommendedForSmsDelivery} onChange={() => handleChange("recommendedForSmsDelivery", !values.recommendedForSmsDelivery)} />
                    </Form.Group>
                </Form>
                <ApiFeedback apiResult={apiResult} successMessage="Eventtype oppdatert!" errorMessage="En feil oppsto!" />
            </Modal.Body>
            <Modal.Footer>
                {apiResult?.success ?
                    <IconButton className="col" icon="close" variant="outline-primary" onClick={handleClose}>Lukk</IconButton> :
                    <>
                        <IconButton className="col" icon="trash" variant="outline-danger" onClick={() => handleDelete(values.id)}>Slett</IconButton>
                        <IconButton className="col" icon="close" variant="outline-primary" onClick={handleClose}>Avbryt</IconButton>
                        <IconButton disabled={submitDisabled} className="col" icon="disk" onClick={() => handleSubmit(values)}>Lagre</IconButton>
                    </>}
            </Modal.Footer>
        </Modal>
    );
};

export default EditModal;