import { useSelectedTsKunde } from "@tradesolution/iceberg-ui-react";
import SupernovaApi from "api/SupernovaApi";
import { ProfileData } from "api/SupernovaApi/models";
import PageWrapper from "components/PageWrapper";
import PersonalSubscriberEventLog from "components/PersonalSubscriberEventLog";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

const VarslingsArkiv = () => {
    const { selectedTsKunde } = useSelectedTsKunde();

    const [profile, setProfile] = useState<ProfileData | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadProfile = async () => {
        setIsLoading(true);
        const p = await SupernovaApi.profile.getByTsKunde(selectedTsKunde.tsKundeId);
        setIsLoading(false);
        setProfile(p);
    }

    useEffect(() => {
        if (selectedTsKunde) {
            loadProfile();
        }
    }, [selectedTsKunde]);

    return (
        <PageWrapper title="Varslingsarkiv">
            {!isLoading && !profile && (
                <Row>
                    <Col>
                        <p>Ingen profildata funnet!</p>
                    </Col>
                </Row>
            )}
            {!isLoading && profile && (
                <Row>
                    <Col>
                        <PersonalSubscriberEventLog personalSubscriberId={profile.id} />
                    </Col>
                </Row>
            )}

        </PageWrapper>
    );
};

export default VarslingsArkiv;