import SupernovaApi from "api/SupernovaApi";
import { EventType } from "api/SupernovaApi/models";
import CommonLoader from "components/CommonLoader";
import TjenestePicker from "components/TjenestePicker";
import { ChangeEvent, useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";

interface Props {
    alreadySelectedEventTypeIds: string[];
    onEventTypesSelected: (eventTypes: EventType[], selectedSource?: string) => void;
}

const EventTypePickerLargeForWebhook = (props: Props) => {
    const [source, setSource] = useState<string>("");
    const [eventTypes, setEventTypes] = useState<EventType[]>([]);
    const [selectedEventTypes, setSelectedEventTypes] = useState<EventType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadEventTypes = async () => {
        setIsLoading(true);
        const et = await SupernovaApi.eventTypes.getMyEventTypes();
        setIsLoading(false);
        setEventTypes(et);
    };

    useEffect(() => {
        loadEventTypes();
    }, []);

    const handleTjenesteSelected = (s: string): void => {
        setSource(s);
        setSelectedEventTypes([]);
    };

    const toggleEventTypeSelected = (eventType: EventType) => {
        let newSelectedEventTypes = [];

        if (selectedEventTypes.includes(eventType)) {
            newSelectedEventTypes = selectedEventTypes.filter(x => x !== eventType);
        } else {
            newSelectedEventTypes = [...selectedEventTypes, eventType];
        }

        setSelectedEventTypes(newSelectedEventTypes);
    };


    useEffect(() => {
        const allEventsForSourceSelected = eventTypes.filter(x => x.source === source).length === selectedEventTypes.length && selectedEventTypes.length > 0;
        props.onEventTypesSelected(selectedEventTypes, allEventsForSourceSelected ? source : undefined);
    }, [selectedEventTypes]);



    const toggleSelectAll = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked) {
            setSelectedEventTypes(eventTypes.filter(x => x.source === source));
        }
        else {
            setSelectedEventTypes([]);
        }
    }

    const getFilteredEventTypes = (): EventType[] => {
        if (!source) {
            return eventTypes.sort((a, b) => a.displayName.localeCompare(b.displayName));
        }
        return eventTypes.filter(x => x.source === source).sort((a, b) => a.displayName.localeCompare(b.displayName));
    }

    return (
        <>
            <Form.Group>
                <Form.Label>Tjeneste</Form.Label>
                <TjenestePicker onChange={handleTjenesteSelected} selectedSource={source} />
            </Form.Group>
            <Form.Group className="mt-3">
                <Row className="mb-3">
                    <Col>
                        {selectedEventTypes.length} av {getFilteredEventTypes().length} varsler valgt
                    </Col>
                </Row>
                {isLoading && (
                    <Row>
                        <Col>
                            <CommonLoader text="Henter eventtyper..." />
                        </Col>
                    </Row>
                )}

                {!isLoading && eventTypes.length === 0 && (
                    <p>Ingen eventtyper</p>
                )}

                {!isLoading && eventTypes.length > 0 && (
                    <div style={{ display: 'block', overflowY: 'auto', maxHeight: '500px' }}>
                        <Table className="tsTable" hover responsive>
                            <thead>
                                <tr>
                                    <th className="fitContent">
                                        <Form.Check
                                            label=""
                                            checked={eventTypes.filter(o => o.source === source).length === selectedEventTypes.length && selectedEventTypes.length > 0}
                                            onChange={toggleSelectAll}
                                            disabled={!source}
                                            style={{ paddingLeft: "1.5em", minHeight: "1em" }}
                                        />
                                    </th>
                                    <th>Hendelse</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {getFilteredEventTypes()
                                    .map(x => (
                                        <tr key={x.id}>
                                            <td className="fitContent">
                                                <Form.Check
                                                    onChange={() => toggleEventTypeSelected(x)}
                                                    disabled={props.alreadySelectedEventTypeIds.includes(x.id)}
                                                    title={props.alreadySelectedEventTypeIds.includes(x.id) ? "Du abonnerer allerede på denne hendelsen" : undefined}
                                                    checked={selectedEventTypes.some(o => o.id === x.id) || props.alreadySelectedEventTypeIds.includes(x.id)}
                                                />
                                            </td>
                                            <td>
                                                <div>
                                                    <strong style={{ fontWeight: 600 }}>{x.displayName}</strong> - {x.source}<br />
                                                    {x.description}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </Form.Group>
        </>
    );
};

export default EventTypePickerLargeForWebhook;