import { IconButton } from "@tradesolution/iceberg-ui-react";
import EmailsApi from "api/EmailsApi";
import SupernovaApi, { ApiResult } from "api/SupernovaApi";
import { UpdateProfileCommand } from "api/SupernovaApi/models";
import ApiFeedback from "components/ApiFeedback";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import useForm from "utils/hooks/useForm";

interface ProfileModalData {
    id: string;
    name: string;
    emailAddress: string;
    mobileNumber: string;
}

interface Props {
    show: boolean;
    data: ProfileModalData;
    onClose: () => void;
}

const ProfileModal = (props: Props) => {
    const [apiResult, setApiResult] = useState<ApiResult<any>>(undefined);

    const initialVals: UpdateProfileCommand = {
        id: props.data.id,
        emailAddress: props.data.emailAddress,
        mobileNumber: props.data.mobileNumber,
        name: props.data.name
    };

    const validate = (values: UpdateProfileCommand) => {
        let err: any = {};
        if (!values.name) err.name = 'Du må fylle inn et navn';

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (values.emailAddress && !emailRegex.test(values.emailAddress)) err.emailAddress = 'Ugyldig e-postadresse';

        if (values.emailAddress?.includes('@trades.no')) err.emailAddress = 'Trades adresser kan ikke motta epost. Vennligst bruk en annen epostadresse';

        const mobileRegex = /^\+?\d{1,15}$/;
        if (values.mobileNumber && !mobileRegex.test(values.mobileNumber)) err.mobileNumber = 'Ugyldig mobilnummer';

        return err;
    };


    const handleClose = () => {
        setApiResult(undefined);
        props.onClose();
    };

    const onSubmit = async (values: UpdateProfileCommand) => {
        const result = await SupernovaApi.profile.update(values);
        setApiResult(result);
    };

    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const verifyEmail = async (email: string) => {
        setIsEmailValid(false);
        const verifyResult = await EmailsApi.verify(email);
        setIsEmailValid(verifyResult.canBeUsed);   
      }

    const { touched, errors, values, submitDisabled, handleSubmit, handleChange, updateValues} =
        useForm(initialVals, validate, onSubmit);

   
    useEffect(() => {
        updateValues({
            id: props.data.id,
            name: props.data.name,
            emailAddress: props.data.emailAddress,
            mobileNumber: props.data.mobileNumber
        })
    }, [props.data]);

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Rediger kontaktinformasjon</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Navn (Brukes i epost og sms)</Form.Label>
                        <Form.Control
                            value={values.name}
                            type="text"
                            onChange={(e) => handleChange('name', e.target.value)}
                            isInvalid={touched.name && !!errors.name}
                            isValid={touched.name && !errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Epost</Form.Label>
                        <Form.Control
                            value={values.emailAddress}
                            type='text'
                            onChange={(e) => handleChange('emailAddress', e.target.value)}
                            onBlur={e => verifyEmail(e.target.value)}
                            isInvalid={(touched.emailAddress && !!errors.emailAddress) || !isEmailValid}
                            isValid={touched.emailAddress && !errors.emailAddress}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.emailAddress}
                        </Form.Control.Feedback>
                        {!isEmailValid && (
                        <div className='invalid-feedback'>
                            <span>Det finnes ingen aktiv e-post konto med denne adressen. Har det sneket seg inn en skrivefeil? Kanskje noen har sluttet i jobben?</span>
                        </div>
                        )}  
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Telefon</Form.Label>
                        <Form.Control
                            value={values.mobileNumber}
                            type='text'
                            onChange={e => handleChange('mobileNumber', e.target.value)}
                            isInvalid={touched.mobileNumber && !!errors.mobileNumber}
                            isValid={touched.mobileNumber && !errors.mobileNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.mobileNumber}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <ApiFeedback apiResult={apiResult} successMessage="Kontaktinformasjon oppdatert!" errorMessage="En feil oppsto!" />
                </Modal.Body>

                <Modal.Footer>
                    {apiResult?.success ?
                        <IconButton icon="close" onClick={handleClose} className="col" variant="outline-primary">Lukk</IconButton> :
                        <>
                            <IconButton icon="close" onClick={handleClose} className="col" variant="outline-primary">Avbryt</IconButton>
                            <IconButton
                                icon="disk"
                                className="col"
                                type='submit'
                                variant="primary"
                                disabled={submitDisabled || !isEmailValid}>
                                Lagre
                            </IconButton>
                        </>
                    }
                </Modal.Footer>
            </Form>
        </Modal >
    );
}
export default ProfileModal;