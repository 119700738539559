
import { IcebergIcon, IconButton } from "@tradesolution/iceberg-ui-react";
import SupernovaApi from "api/SupernovaApi";
import { PagedResult, ProfileData, TsKunde } from "api/SupernovaApi/models";
import CommonLoader from "components/CommonLoader";
import PageWrapper from "components/PageWrapper";
import TsKundeTypeahead from "components/TsKundeTypeahead";
import useToaster from "framework/Toaster/useToaster";
import { useEffect, useState } from "react";
import { Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import { useDebounce } from "utils/hooks/useDebounce";


const PersonalSubscribersAdmin = () => {

    const toaster = useToaster();

    const [q, setQ] = useState<string>("");
    const qDebounced = useDebounce(q, 500);

    const [selectedTsKunde, setSelectedTsKunde] = useState<TsKunde | undefined>(undefined);

    const [subscribers, setSubscribers] = useState<PagedResult<ProfileData>>({ items: [], totalCount: 0 });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const take = 40;
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState<boolean | null>(null);
    const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);

    const loadSubscribers = async (tsKundeId?: string, query?: string) => {
        setIsLoading(page === 1);
        setIsFetchingMore(page > 1);

        try {
            const result = await SupernovaApi.getPersonalSubscribers(tsKundeId, query, take * page);

            if (page === 1) {
                setSubscribers(result);
            } else {
                setSubscribers(prev => ({
                    ...result,
                    items: [...prev.items, ...result.items.slice(prev.items.length)]
                }));
            }

            setHasMore(result.items.length >= (take * page));
        } catch (error) {
            console.error('Feil ved innlasting av abonnenter:', error);
        } finally {
            setIsLoading(false);
            setIsFetchingMore(false);
        }
    };


    useEffect(() => {
        loadSubscribers(selectedTsKunde?.id, qDebounced);
    }, [selectedTsKunde, qDebounced, page]);

    useEffect(() => {
        setPage(1);
    }, [selectedTsKunde, qDebounced]);

    const handleTsKundeSelected = (kunde: TsKunde): void => {
        setSelectedTsKunde(kunde);
    }

    const removeSubscriber = async (subscriber: ProfileData): Promise<void> => {

        const result = await SupernovaApi.profile.delete(subscriber.id);
        if (result.success) {
            toaster.success('Abonnent fjernet');
        }
        else {
            toaster.error('Noe gikk galt');
        }
        loadSubscribers(selectedTsKunde?.id);
    }

    return (
        <PageWrapper title="Administrer abonnenter">
            <Form className="mb-3">
                <Row className="align-items-center">
                    <Col md={4}>
                        <Form.Control type="text" placeholder="Søk på navn, epost, sms, brukerid eller objectid" value={q} onChange={e => setQ(e.target.value)} />
                    </Col>
                    <Col col={4} className="text-center">
                        {!isLoading && `Viser ${subscribers.items.length} av ${subscribers.totalCount} abonnenter`}
                    </Col>
                    <Col>
                        <TsKundeTypeahead onChange={handleTsKundeSelected} />
                    </Col>
                </Row>
            </Form>
            {isLoading ? null: (
                <>
                    {subscribers.items.length === 0 && (<p>Ingen abonnenter</p>)}
                    {subscribers.items.length > 0 && (
                        <Table className="tsTable" hover responsive>
                            <thead>
                                <tr>
                                    <th>Navn</th>
                                    <th>TsKunde</th>
                                    <th>Epost</th>
                                    <th className="text-center">Sms</th>
                                    <th className="text-center">Antall abb.</th>
                                    <th className="text-center">Antall epost sub.</th>
                                    <th className="text-center">Antall epost umiddelbart</th>
                                    <th className="text-center">Antall sms sub.</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {subscribers.items.map(s => <tr key={s.id}>
                                    <td>{s.name}</td>
                                    <td>{s.tsKundeName}</td>
                                    <td>{s.emailAddress ?? ""}</td>
                                    <td className="text-center">{s.phoneNumber ?? ""}</td>
                                    <td className="text-center">{s.subscribedEventTypes.length}</td>
                                    <td className="text-center">{s.subscribedEventTypes.filter(o => o.delayedEmailDelivery).length}</td>
                                    <td className="text-center">{s.subscribedEventTypes.filter(o => o.imidiateEmailDelivery).length}</td>
                                    <td className="text-center">{s.subscribedEventTypes.filter(o => o.smsDelivery).length}</td>
                                    <td className="fitContent">
                                        <IconButton icon="edit" variant="outline-primary" title="Rediger abonnent" href={"/abonnent/" + s.id}>Rediger</IconButton>
                                    </td>
                                    <td className="fitContent">
                                        <Dropdown className="noCaret">
                                            <Dropdown.Toggle style={{ padding: "0.3rem" }} variant="none" id="dropdown-basic">
                                                <IcebergIcon icon="ellipsis" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={(e) => removeSubscriber(s)}>Slett abonnent</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    )}
                </>
            )}

            <Row className="p-3">
                <Col style={{ textAlign: 'center' }}>
                    {isLoading && !isFetchingMore && (
                        <CommonLoader text="Henter..." />
                    )}
                    {isFetchingMore && (
                        <CommonLoader text="Henter mer..." />
                    )}
                    {!isLoading && !isFetchingMore && hasMore && (
                        <button type="button" className="btn btn-outline-primary"
                            onClick={() => setPage(page + 1)}>Last flere</button>
                    )}
                </Col>
            </Row>
        </PageWrapper>
    )
};

export default PersonalSubscribersAdmin;