import { IcebergDatepicker, IconButton } from "@tradesolution/iceberg-ui-react";
import SupernovaApi, { ApiResult } from "api/SupernovaApi";
import { ResendManyCommand } from "api/SupernovaApi/models";
import ApiFeedback from "components/ApiFeedback";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import useForm from "utils/hooks/useForm";

interface Props {
    show: boolean;
    webhookId: string;
    onClose: () => void;
}

const ResendManyModal = (props: Props) => {
    const [apiResult, setApiResult] = useState<ApiResult<any>>(undefined);
    const [earliestDateToResendFrom, setEarliestDateToResendFrom] = useState<Date>(new Date());

    const handleClose = () => {
        setApiResult(undefined);
        props.onClose();
    };

    const onSubmit = async () => {
        const result = await SupernovaApi.webhooks.resendMany(props.webhookId, { earliestDateToResendFrom: earliestDateToResendFrom });
        setApiResult(result);
    };

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Resend flere feilede varsler</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Tidligste fra og med levertdato</Form.Label>
                    <IcebergDatepicker
                        value={earliestDateToResendFrom}
                        onChange={(date) => setEarliestDateToResendFrom(date)}
                    />
                </Form.Group>
                <ApiFeedback apiResult={apiResult} successMessage={'Resendt ' + apiResult?.data + ' feilede varsler'} errorMessage="En feil oppsto!" />
            </Modal.Body>

            <Modal.Footer>
                {apiResult?.success ?
                    <IconButton icon="close" onClick={handleClose} className="col" variant="outline-primary">Lukk</IconButton> :
                    <>
                        <IconButton icon="close" onClick={handleClose} className="col" variant="outline-primary">Avbryt</IconButton>
                        <IconButton
                            icon="arrow-right"
                            className="col"
                            type='button'
                            onClick={onSubmit}
                            variant="primary">
                            Utfør
                        </IconButton>
                    </>
                }
            </Modal.Footer>
        </Modal >
    );
}
export default ResendManyModal;