import { IcebergIcon, IconButton, useSelectedTsKunde } from "@tradesolution/iceberg-ui-react";
import SupernovaApi from "api/SupernovaApi";
import { Webhook } from "api/SupernovaApi/models";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { Col, Dropdown, Row, Table } from "react-bootstrap";
import EditModal from "./EditModal";
import AddModal from "./AddModal";
import TestModal from "./TestModal";
import SubscribeModal from "./SubscribeModal";
import AuditLogModal from "./AuditLogModal";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.scss";
import UrlHelper from "framework/UrlHelper";
import CommonLoader from "components/CommonLoader";

const Webhooks = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const isAdmin = location.pathname.includes("admin");

    const { selectedTsKunde } = useSelectedTsKunde();

    const [webhooks, setWebhooks] = useState<Webhook[]>([]);
    const [selected, setSelected] = useState<Webhook | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadWebhooks = async (tsKundeId?: string) => {
        setIsLoading(true);
        const s = await SupernovaApi.webhooks.getWebhooks(tsKundeId);
        setIsLoading(false);
        setWebhooks(s);
    };

    useEffect(() => {
        loadWebhooks(isAdmin ? undefined : selectedTsKunde?.tsKundeId);
    }, [selectedTsKunde]);

    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const editWebhook = (webhook: Webhook) => {
        setSelected(webhook);
        setShowEditModal(true);
    };

    const handleEditClose = () => {
        setSelected(undefined);
        setShowEditModal(false);
        loadWebhooks();
    };

    const handleAddClose = () => {
        setShowAddModal(false);
        loadWebhooks();
    };

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const showAddWebhook = () => {
        setShowAddModal(true);
    };

    const [showTestModal, setShowTestModal] = useState<boolean>(false);
    const testWebhook = (webhook: Webhook) => {
        setSelected(webhook);
        setShowTestModal(true);
    };

    const handleTestClose = () => {
        setSelected(undefined);
        setShowTestModal(false);
    };

    const [showAuditModal, setShowAuditModal] = useState<boolean>(false);
    const auditWebhook = (webhook: Webhook) => {
        setSelected(webhook);
        setShowAuditModal(true);
    };

    const handleAuditClose = () => {
        setSelected(undefined);
        setShowAuditModal(false);
    };

    const [showSubscribeModal, setShowSubscribeModal] = useState<boolean>(false);

    const handleSubscribeClose = () => {
        setSelected(undefined);
        setShowSubscribeModal(false);
    };

    const editAbonnement = (webhook: Webhook) => {
        navigate(`/webhooks/${webhook.id}/abonnement`);
    };

    return (
        <PageWrapper
            title={isAdmin ? "Admin webhooks" : "Webhooks"}
            rightContent={
                <IconButton icon="plus" variant="primary" onClick={showAddWebhook}>Ny webhook</IconButton>
            }
        >
            {isLoading && (
                <Row>
                    <Col>
                        <CommonLoader text="Henter..."/>
                    </Col>
                </Row>
            )}
            {!isLoading && webhooks.length === 0 && (<p>Ingen webhooks er registrert for denne kunden enda</p>)}
            {!isLoading && webhooks.length > 0 && (
                <Row>
                    <Col>

                        <Table className="tsTable" hover responsive>
                            <thead>
                                <tr>
                                    <th>Navn</th>
                                    <th>{isAdmin ? "TsKunde" : ""}</th>
                                    <th className="text-center">Antall abb.</th>
                                    <th className="text-center"></th>
                                    <th className="fitContent"></th>
                                    <th className="fitContent"></th>
                                    <th className="fitContent"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {webhooks.map((x, i) => (
                                    <tr key={x.id}>
                                        <td>
                                            <div>
                                                <span title={x.webhookUri} style={{ fontWeight: "600" }}>{x.name}</span><br />
                                                <span title={x.webhookUri} style={{ fontSize: "smaller" }} className="hiddenOnSmallScreen">{UrlHelper.shortUrl(x.webhookUri, 100)}</span>
                                            </div>
                                        </td>
                                        <td>{isAdmin ? <><span>{x.tsKundeNavn}</span><br /></> : ""}</td>
                                        <td className="text-center">
                                            {x.subscribedToEventTypeIds.length}
                                        </td>
                                        <td className="fitContent">
                                            <IconButton icon="document-detail" size='sm' variant="outline-primary" onClick={() => navigate(`/webhooks/${x.id}/logs`)}>Eventlog</IconButton>
                                        </td>
                                        <td className="fitContent">
                                            <IconButton icon="envelope" size="sm" variant="outline-primary" onClick={() => editAbonnement(x)}>Abonnement</IconButton>
                                        </td>
                                        <td className="fitContent">
                                            <IconButton icon="edit" size="sm" variant="outline-primary" onClick={() => editWebhook(x)}>Endre</IconButton>
                                        </td>
                                        <td className="fitContent">
                                            <Dropdown className="noCaret">
                                                <Dropdown.Toggle style={{ padding: "0.3rem" }} variant="none" id="dropdown-basic">
                                                    <IcebergIcon icon="ellipsis" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => testWebhook(x)}>Test</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => auditWebhook(x)}>Auditlog</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            <AddModal show={showAddModal} onClose={handleAddClose} isAdmin={isAdmin} />
            <EditModal show={showEditModal} webhook={selected} onClose={handleEditClose} isAdmin={isAdmin} />
            <TestModal show={showTestModal} webhook={selected} onClose={handleTestClose} />
            <AuditLogModal show={showAuditModal} webhook={selected} onClose={handleAuditClose} />
            <SubscribeModal show={showSubscribeModal} webhook={selected} onClose={handleSubscribeClose} />
        </PageWrapper>
    );
};

export default Webhooks;