import { IconButton } from "@tradesolution/iceberg-ui-react";
import { CustomHeader } from "api/SupernovaApi/models";
import { Col, Form, Row } from "react-bootstrap";

interface Props {
    customHeaders: CustomHeader[];
    onChange: (customHeaders: CustomHeader[]) => void;
}

const CustomHeaderEditor = (props: Props) => {

    const addCustomHeader = () => {
        props.onChange([...props.customHeaders, { key: "", value: "" }]);
    };

    const updateCustomHeaderKey = (index: number, value: string) => {
        const newHeaders = [...props.customHeaders];
        newHeaders[index].key = value;
        props.onChange(newHeaders);
    };

    const updateCustomHeaderValue = (index: number, value: string) => {
        const newHeaders = [...props.customHeaders];
        newHeaders[index].value = value;
        props.onChange(newHeaders);
    };

    const removeCustomHeader = (index: number) => {
        const newHeaders = [...props.customHeaders];
        newHeaders.splice(index, 1);
        props.onChange(newHeaders);
    };

    return (
        <Form.Group className="mb-3">
            <Form.Label>Custom HTTP headers</Form.Label>
            {props.customHeaders.map((header, index) => (
                <Row key={index} className="mb-2">
                    <Col>
                        <Form.Label>Key</Form.Label>
                        <Form.Control type="text" name="name" value={header.key} onChange={e => updateCustomHeaderKey(index, e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Value</Form.Label>
                        <Form.Control type="text" name="value" value={header.value} onChange={e => updateCustomHeaderValue(index, e.target.value)} />
                    </Col>
                    <Col md={3}>
                        <Form.Label>&nbsp;</Form.Label><br></br>
                        <IconButton icon="trash" size="sm" variant="outline-danger" title="Fjern" onClick={() => removeCustomHeader(index)}></IconButton>
                    </Col>
                </Row>
            )
            )}
            <div>
                <IconButton icon="plus" size="sm" variant="outline-primary" onClick={addCustomHeader}>Legg til</IconButton>
            </div>
        </Form.Group>
    )
};

export default CustomHeaderEditor;