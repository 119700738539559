import { IconButton } from "@tradesolution/iceberg-ui-react";
import SupernovaApi, { ApiResult } from "api/SupernovaApi";
import { EventType, ProfileData } from "api/SupernovaApi/models";
import ApiFeedback from "components/ApiFeedback";
import EventTypePickerLarge from "components/EventTypePickerLarge";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";

interface Props {
    show: boolean;
    profile?: ProfileData;
    onClose: () => void;
}

const SubscribeModal = (props: Props) => {

    const handleClose = () => {
        setApiResult(undefined);
        props.onClose();
    };

    const [selectedEventTypes, setSelectedEventTypes] = useState<EventType[]>([]);
    const [selectedSource, setSelectedSource] = useState<string | undefined>();

    const handleEventTypesSelected = (eventTypes: EventType[], source?: string): void => {
        setSelectedEventTypes(eventTypes);
        if (source) {
            setSelectedSource(source);
        }
        setApiResult(undefined);
    }

    const [apiResult, setApiResult] = useState<ApiResult<any>>(undefined);
    const handleSave = async () => {
        if (selectedEventTypes.length > 0) {
            const result = await SupernovaApi.profile.subscribeToEvents(props.profile?.id, selectedEventTypes?.map(x => x.id));
            setApiResult(result);
        }
    }

    return (
        <Modal show={props.show && !!props.profile} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Abonner på hendelser</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <EventTypePickerLarge
                        onEventTypesSelected={handleEventTypesSelected}
                        alreadySelectedEventTypeIds={props.profile?.subscribedEventTypes?.map(x => x.id) || []}
                    />
                    <ApiFeedback apiResult={apiResult} successMessage="Abonnement oppdatert!" errorMessage="En feil oppsto!" />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {apiResult?.success ?
                    <IconButton icon="close" onClick={handleClose} className="col" variant="outline-primary">Lukk</IconButton> :
                    <>
                        <IconButton icon="close" onClick={handleClose} className="col" variant="outline-primary">Avbryt</IconButton>
                        <IconButton icon="disk" onClick={handleSave} className="col" variant="primary">Lagre</IconButton>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default SubscribeModal;