import { IconButton } from "@tradesolution/iceberg-ui-react";
import SupernovaApi from "api/SupernovaApi";
import { AuditLogEntry, Webhook } from "api/SupernovaApi/models";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";

interface Props {
    show: boolean;
    webhook?: Webhook;
    onClose: () => void;
}

const AuditLogModal = (props: Props) => {

    const handleClose = () => {
        setAuditLog([]);
        props.onClose();
    };

    const [auditLog, setAuditLog] = useState<AuditLogEntry[]>([]);

    useEffect(() => {
        const load = async (id: string) => {
            const result = await SupernovaApi.webhooks.auditLog(id);
            setAuditLog(result);
        };
        if (props.webhook?.id) {
            load(props.webhook?.id);
        }
    }, [props.webhook?.id]);

    return (
        <Modal size="xl" show={props.show && !!props.webhook} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Auditlog for {props.webhook?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Endringer gjort på webhook registrering</p>
                <Table className="tsTable" hover>
                    <thead>
                        <tr>
                            <th>Hendelse</th>
                            <th>Tidspunkt</th>
                            <th>Utført av</th>
                            <th>Innhold</th>
                        </tr>
                    </thead>
                    <tbody>
                        {auditLog.map(a => <tr>
                            <td>{a.event}</td>
                            <td>{format(a.timestamp, 'dd.MM.yyyy HH:mm:ss')}</td>
                            <td>{a.username}</td>
                            <td>{Object.entries(a.content).map(
                                ([key, value]) => <div><b>{key}</b>: {value}</div>
                            )}</td>
                        </tr>)}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <IconButton icon="close" onClick={handleClose} className="col" variant="outline-primary">Lukk</IconButton>
            </Modal.Footer>
        </Modal>
    );
}

export default AuditLogModal;