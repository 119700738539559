import 'Layout.scss';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from "./authConfig";
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from 'Layout';
import VarslingsArkiv from 'pages/VarslingsArkiv';
import VarslingsInnstillinger from 'pages/VarslingsInnstillinger';
import EventLog from 'pages/EventLog';
import EventTypes from 'pages/EventTypes';
import Webhooks from 'pages/Webhooks';
import Subscribers from 'pages/EventTypes/Subscribers';
import WebhookEventLog from 'pages/Webhooks/WebhookEventLog';
import ToastProvider from 'framework/Toaster/ToastProvider';
import Abonnement from 'pages/Webhooks/Abonnement';
import PersonalSubscribersAdmin from 'pages/PersonalSubscribersAdmin';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

LogRocket.init('msbcwv/varslingtradesolutionno');
setupLogRocketReact(LogRocket);

const reactPlugin = new ReactPlugin();

// Supernova AI if prod, else Supernova-Dev AI
const connectionString = window.location.host === 'varsling.tradesolution.no'
  ? 'InstrumentationKey=97a2cc3b-50a7-4439-a355-3c991139c4d4;IngestionEndpoint=https://westeurope-3.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/'
  : 'InstrumentationKey=ffed48ab-f9ef-45b5-9b93-a6a8ccc341f9;IngestionEndpoint=https://westeurope-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/';

const appInsights = new ApplicationInsights({
  config: {
    connectionString: connectionString,
    enableAutoRouteTracking: true,
    // *** If you're adding the Click Analytics plug-in, delete the next line. ***
    extensions: [reactPlugin]
  }
});
appInsights.loadAppInsights();

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <MsalProvider instance={msalInstance}>
    <ToastProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<VarslingsInnstillinger />} />
            <Route path='abonnent/:id' element={<VarslingsInnstillinger />} />
            <Route path="arkiv" element={<VarslingsArkiv />} />
            <Route path="eventlog" element={<EventLog />} />
            <Route path="eventtypes" element={<EventTypes />} />
            <Route path="eventtypes/:id/subscribers" element={<Subscribers />} />
            <Route path="mywebhooks" element={<Webhooks />} />
            <Route path="webhooks/:id/logs" element={<WebhookEventLog />} />
            <Route path="webhooks/:id/abonnement" element={<Abonnement />} />
            <Route path="webhookadmin" element={<Webhooks />} />
            <Route path="useradmin" element={<PersonalSubscribersAdmin />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ToastProvider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
