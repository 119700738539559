import { useEffect, useState } from "react";
import Sidebar, { NavLink } from ".";
import KundeportalenApi from "api/KundeportalenApi";
import { Permission } from "api/KundeportalenApi/models";

const SidebarWrapper = () => {
    const navLinks: NavLink[] = [
        { label: 'Varslingsinnstillinger', link: '/', icon: 'settings' },
        { label: 'Varslingsarkiv', link: '/arkiv', icon: 'archive' },
        { label: 'Webhooks', link: '/mywebhooks', icon: 'globe', roles: ['UberAdmin', 'TradesolutionAnsatt', 'Priviligert'] },
        { label: 'Eventtyper', link: '/eventtypes', icon: 'envelope', roles: ['UberAdmin', 'TradesolutionAnsatt'] },
        { label: 'Eventlog', link: '/eventlog', icon: 'table', roles: ['UberAdmin', 'TradesolutionAnsatt'] },
        { label: 'Admin webooks', link: '/webhookadmin', icon: 'globe', roles: ['UberAdmin', 'TradesolutionAnsatt'] },
        { label: 'Admin abonnenter', link: '/useradmin', icon: 'users', roles: ['UberAdmin', 'TradesolutionAnsatt'] }
    ];

    const [permissions, setPermissions] = useState<Permission[]>([]);

    const loadPermissions = async () => {
        const data = await KundeportalenApi.getPermissions();
        setPermissions(data);
    }

    useEffect(() => {
        loadPermissions();
    }, []);

    const [filteredNavLinks, setFilteredNavLinks] = useState<NavLink[]>([]);

    useEffect(() => {
        if (permissions.length === 0) {
            setFilteredNavLinks(navLinks.filter(o => !o.roles));
        }
        else {
            setFilteredNavLinks(navLinks.filter(o => !o.roles || o.roles.some(r => permissions.some(p => p.role === r))));
        }
    }, [permissions]);

    return <Sidebar navLinks={filteredNavLinks} />
}

export default SidebarWrapper;