import { Col, Row } from "react-bootstrap";

interface Props {
    title: string;
    children: React.ReactNode;
    rightContent?: React.ReactNode;
}

const PageWrapper = (props: Props) => {
    return <div>
        <Row className="mt-4 mb-4">
            <Col>
                <h1>{props.title}</h1>
            </Col>
            {props.rightContent && <Col md={4} style={{ textAlign: "right" }}>
                {props.rightContent}
            </Col>
            }
        </Row>
        {props.children}
    </div>
};

export default PageWrapper;