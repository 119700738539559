import { ApiResult } from "api/SupernovaApi";
import ErrorFeedback from "components/Feedback/Error";
import SuccessFeedback from "components/Feedback/Success";

interface Props {
    apiResult?: ApiResult<any>;
    successMessage?: string;
    errorMessage?: string;
}

const ApiFeedback = (props: Props) => {
    if (!props.apiResult)
        return null;

    if (props.apiResult.success)
        return (
            <SuccessFeedback text={props.successMessage ?? "Operasjonen ble utført"} />
        );
    if (!props.apiResult.success)
        return (
            <ErrorFeedback text={(props.errorMessage ?? "En feil oppsto") + ": " + props.apiResult.error} />
        );
}

export default ApiFeedback;