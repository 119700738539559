import * as _ from "lodash";

export interface LogEntry {
    eventId: string;
    recievedEvent: RecievedEvent;
    eventTypeName: string;
    deliveryStart: Date;
    initialDeliveryAt: Date;
    durationInMilliseconds: number;
    numberOfSuccesses: number;
    successfullDeliveries: SuccessfulDelivery[];
    numberOfFailures: number;
    failedAttempts: any[];
    source: Source;
    waitBeforeProcessingBeganInSec: number;
    searchableText: string;
}

export interface RecievedEvent {
    eventId: string;
    event: string;
    source: Source;
    payload: Payload;
    claims: Claim[];
    triggeredBy: null | string;
    triggeredAt: Date;
    apiUrl: null | string;
    userInterfaceUrl: null | string;
    displayMessage: null | string;
    eventTypeDisplayNameToUseInsteadOfDefaultName: string;
}

export interface Claim {
    key: Key;
    value: string;
}

export enum Key {
    AccessTo = "AccessTo",
    TsKundeID = "TsKundeId",
}

export enum Source {
    Admin = "ADMIN",
    Epd = "EPD",
    Increase = "INCREASE",
    Instore = "INSTORE",
    Mediastore = "MEDIASTORE",
}

export interface Payload {
    SoknadID?: number;
    EPDNr?: number;
    ProduktNavn?: string;
    Id?: string;
    BlobUrl?: string;
    KontaktId?: string;
    Salgsapparat?: string;
    LopeNr?: number;
    Gtin?: number;
    ProductName?: string;
    EpdNumbers?: number[];
    EpdNummer?: number;
    Produktnavn?: string;
    KladdProduktID?: number;
    BrukerId?: string;
    ObjectId?: string;
    Email?: string;
    PermissionChanges?: PermissionChange[];
    AccessChanges?: AccessChange[];
    EpdNr?: number[];
    Navn?: string;
    TsKundeId?: string;
    TsKundeName?: string;
    Name?: string;
    Mobile?: string;
    TypeKontaktPerson?: string;
    NedlagtDato?: string;
}

export interface AccessChange {
    Type: string;
    Value: string;
    ScopedToTsKundeId: string;
    ScopedToAccessToClaim: string;
}

export interface PermissionChange {
    TsKundeId: string;
    AccessTo: string;
    Role: null;
}

export interface SuccessfulDelivery {
    subscriberId: null | string;
    deliveredTo: string;
    durationInMilliseconds: number;
}

export interface EventType {
    id: string;
    description: string;
    displayName: string;
    distributionState: number;
    eventTypeName: string;
    notAvailableForSourceSubscriptions: boolean;
    payload: string;
    source: string;
    lastEventProcessedAt: Date | undefined
    recommendedForDelayedEmailDelivery: boolean;
    recommendedForImidiateEmailDelivery: boolean;
    recommendedForSmsDelivery: boolean;
}

export interface Webhook {
    id: string;
    name: string;
    tsKundeId: string;
    tsKundeNavn: string;
    webhookUri: string;
    customHeaders: CustomHeader[];
    subscribedToEventTypeIds: string[];
    subscribedSources: string[];
    contactEmails: string[];
}

export interface CustomHeader {
    key: string;
    value: string;
}

export interface SourceItem {
    claimName: string;
    isInternal: boolean;
    name: string;
}

export interface ProfileData {
    id: string
    brukerId: string
    objectId: string
    name: string
    tsKundeId: string
    subscribedToEventTypeIds: any[]
    tsKundeName: string
    subscribedEventTypes: SubscribedEventType[]
    subscribedSources: any[],
    emailAddress: string,
    phoneNumber: string
}

export interface SubscribedEventType extends EventType {
    isSubscribed: boolean
    id: string
    eventTypeName: string
    displayName: string
    description: string
    payload: string
    source: string
    distributionState: number
    notAvailableForSourceSubscriptions: boolean
    imidiateEmailDelivery: boolean
    delayedEmailDelivery: boolean
    smsDelivery: boolean
}

export interface PersonalLogEntry {
    displayMessage: string
    event: string
    eventId: string
    eventTypeId: string
    id: string
    payload: {
        gtin: string
    }
    personalSubscriberId: string
    readAt: any
    seenAt: any
    source: string
    userInterfaceUrl: string
    typeName: string
    eventType: EventType
    triggeredAt: Date
}

export interface EventTypeUpdateCommand {
    id: string;
    eventTypeName: string;
    displayName: string;
    description: string;
    payload: string;
    source: string;
    distributionState: number;
    notAvailableForSourceSubscriptions: boolean;
    recommendedForDelayedEmailDelivery: boolean;
    recommendedForImidiateEmailDelivery: boolean;
    recommendedForSmsDelivery: boolean;
}

export interface EventTypeCreateCommand {
    eventTypeName: string;
    displayName: string;
    description: string;
    payload: string;
    source: string;
    distributionState: number;
    notAvailableForSourceSubscriptions: boolean;
    recommendedForDelayedEmailDelivery: boolean;
    recommendedForImidiateEmailDelivery: boolean;
    recommendedForSmsDelivery: boolean;
}

export interface UnsubscribeFromAllCommand{
    tsKundeId: string;
}

export interface WebhookSubscriber {
    id: string
    tsKundeId: string
    name: string
    webhookUri: string
    customHeaders: any[]
    subscribedToEventTypeIds: string[]
    tsKundeNavn: string
    subscribedSources: string[]
    contactEmails: string[]
}

export enum SubscriptionMethod {
    None, Web, Email, EmailImidiate, Sms
}

export interface UpdateWebhookCommand {
    id: string;
    name: string;
    webhookUri: string;
    tsKundeId: string;
    customHeaders: CustomHeader[];
    contactEmails: string[];
}

export interface CreateWebhookCommand {
    tsKundeId: string;
    name: string;
    webhookUri: string;
    customHeaders: CustomHeader[];
    contactEmails: string[];
}

export interface UpdateProfileCommand {
    id: string;
    emailAddress?: string;
    mobileNumber?: string;
    name?: string;
}

export interface ResendManyCommand {
    earliestDateToResendFrom: Date;
}

export interface WebhookLogEntry {
    eventId: string;
    initialDeliveryAttemptedAt: Date;
    succesfullyDeliveredAt: Date;
    durationInMilliseconds: number;
    event: string;
    source: string;
    webhookRequest: WebhookRequest;
    failedAttempts: FailedAttempt[];
    reSentAt?: Date;
}

export interface WebhookRequest {
    eventId: string;
    event: string;
    source: string;
    data: string;
    timestamp: Date;
    text: string;
}

export interface FailedAttempt {
    attemptedAt: Date;
    errorMessage: string;
    triedDeliveryTo: string;
}

export interface GroupedEventTypes {
    source: string;
    eventTypes: EventType[];
}

export interface AuditLogEntry {
    id: string
    username: string
    event: string
    content: AuditLogContent
    documentId: string
    timestamp: Date
}

export interface AuditLogContent {
    Name: string
    "Custom Headers": string
    TsKundeId: string
    WebhookUri: string
}

export interface TsKunde {
    id: string;
    name: string;
}

export interface PagedResult<T> {
    items: T[];
    totalCount: number;
}

export class EventTypes {
    static groupBySource(eventTypes: EventType[]): GroupedEventTypes[] {
        return Object.entries(_.groupBy(eventTypes, 'source')).map(o => ({ source: o[0], eventTypes: o[1] as any }));
    }
}