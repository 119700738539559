import { IconButton } from "@tradesolution/iceberg-ui-react";
import { TsKunde, UpdateWebhookCommand, Webhook } from "api/SupernovaApi/models";
import { Form, Modal } from "react-bootstrap";
import useForm from "utils/hooks/useForm";
import CustomHeaderEditor from "../CustomHeaderEditor";
import ContactEmailEditor from "../ContactEmailEditor";
import { useEffect, useState } from "react";
import SupernovaApi, { ApiResult } from "api/SupernovaApi";
import ApiFeedback from "components/ApiFeedback";
import TsKundeTypeahead from "components/TsKundeTypeahead";

interface Props {
    show: boolean;
    isAdmin: boolean;
    webhook?: Webhook;
    onClose: () => void;
}

const EditModal = (props: Props) => {

    const mapToCommand = (webhook: Webhook): UpdateWebhookCommand => {
        return {
            id: webhook.id,
            name: webhook.name,
            tsKundeId: webhook.tsKundeId,
            webhookUri: webhook.webhookUri,
            customHeaders: webhook.customHeaders ?? [],
            contactEmails: webhook.contactEmails ?? [],
        };
    };

    const defaultValues = props.webhook ? mapToCommand(props.webhook) : {
        id: "",
        name: "",
        webhookUri: "",
        tsKundeId: "",
        customHeaders: [],
        contactEmails: [],
    };

    const [apiResult, setApiResult] = useState<ApiResult<Webhook> | undefined>();

    const validate = (values: UpdateWebhookCommand) => {
        const errors: any = {};
        if (!values.name) errors.name = "Navn må fylles ut";
        if (!values.webhookUri) errors.webhookUri = "Webhook url må fylles ut";
        return errors;
    };

    const handleSubmit = async (command: UpdateWebhookCommand): Promise<ApiResult<Webhook>> => {
        const result = await SupernovaApi.webhooks.updateWebhook(command);
        setApiResult(result);
        return result;
    };

    const { values, handleChange, resetForm, errors, submitDisabled } = useForm<UpdateWebhookCommand, ApiResult<Webhook>>(defaultValues, validate, handleSubmit);

    // whenever user clicks on a new webhook, reset the form
    useEffect(() => {
        resetForm(defaultValues);
        setApiResult(undefined);
        setAdminSelectedTsKunde({
            id: props.webhook?.tsKundeId ?? "",
            name: props.webhook?.tsKundeNavn ?? "",
        });
    }, [props.webhook]);

    const handleClose = () => {
        resetForm(defaultValues);
        setApiResult(undefined);
        props.onClose();
    };

    const handleDelete = async (webhookId: string) => {
        const result = await SupernovaApi.webhooks.deleteWebhook(webhookId);
        setApiResult(result);
        return result;
    };

    const [adminSelectedTsKunde, setAdminSelectedTsKunde] = useState<TsKunde | undefined>(undefined);

    const handleTsKundeSelected = (kunde?: TsKunde): void => {
        if (kunde) {
            setAdminSelectedTsKunde(kunde);
            handleChange("tsKundeId", kunde.id);
        }
    }

    return (
        <Modal show={props.show && !!props.webhook} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.webhook?.name || ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Navn</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={e => handleChange("name", e.target.value)}
                            isInvalid={!!errors.name}
                            isValid={!!values.name && !errors.name}
                        />
                        <Form.Control.Feedback type="invalid">{errors?.name}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Webhook url</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={values.webhookUri}
                            onChange={e => handleChange("webhookUri", e.target.value)}
                            isInvalid={!!errors.webhookUri}
                            isValid={!!values.webhookUri && !errors.webhookUri}
                        />
                        <Form.Control.Feedback type="invalid">{errors?.webhookUri}</Form.Control.Feedback>
                    </Form.Group>
                    {props.isAdmin && <Form.Group>
                        <Form.Label>Kunde</Form.Label>
                        <TsKundeTypeahead onChange={handleTsKundeSelected} selected={adminSelectedTsKunde} />
                    </Form.Group>}
                    <CustomHeaderEditor customHeaders={values.customHeaders} onChange={e => handleChange("customHeaders", e)} />
                    <ContactEmailEditor emails={values.contactEmails} onChange={e => handleChange("contactEmails", e)} />
                </Form>
                <ApiFeedback apiResult={apiResult} successMessage="Webhook oppdatert!" errorMessage="En feil oppsto!" />
            </Modal.Body>
            <Modal.Footer>
                {!!apiResult && <IconButton className="col" icon="close" variant="outline-primary" onClick={handleClose}>Lukk</IconButton>}
                {!apiResult && <>
                    <IconButton className="col" icon="trash" variant="outline-danger" onClick={() => handleDelete(values.id)}>Slett</IconButton>
                    <IconButton className="col" icon="close" variant="outline-primary" onClick={handleClose}>Avbryt</IconButton>
                    <IconButton disabled={submitDisabled} className="col" icon="disk" onClick={() => handleSubmit(values)}>Lagre</IconButton>
                </>}
            </Modal.Footer>
        </Modal>
    )
}

export default EditModal;