import { IconButton } from "@tradesolution/iceberg-ui-react";
import TjenestePicker from "components/TjenestePicker";
import { Form, FormGroup, Offcanvas,  OffcanvasBody } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "../index.scss";
import { useEffect, useState } from "react";
import{ OffcanvasPlacement } from "react-bootstrap/esm/Offcanvas";


const FilterWebhookEventLog = ({
    handleTjenesteChanged,
    setKunUleverte,
    kunUleverte,
    setStartDate,
    startDate,
    tjeneste,
    onClose,
    show }) => {

    const [placement, setPlacement] = useState<OffcanvasPlacement>(window.innerWidth <= 768 ? 'bottom' : 'end');
    const [height, setHeight] = useState(506);

    useEffect(() => {
        const handleResize = () => {
            setPlacement(window.innerWidth <= 768 ? 'bottom' : 'end');
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Offcanvas
            onHide={onClose}
            show={show}
            placement={placement}
            className='offcanvasStyle'
            backdropClassName='offcanvasBackdrop'
            style={{ height: window.innerWidth <= 768 ? `${height}px` : '100%' }}
        >
            <OffcanvasBody>
                <Form>
                    <div className='formContainer'>
                        <FormGroup className="p-3 d-flex justify-content-between align-items-center">
                            <h4>Filter</h4>
                            <IconButton variant='' icon='close' onClick={onClose} style={{ marginTop: '-7px', padding: '0px' }} />
                        </FormGroup>

                        <FormGroup className="p-3" style={{ order: window.innerWidth <= 768 ? 99 : 0 }}>
                            <IconButton
                                icon="synchronize"
                                size="sm"
                                variant="outline-primary"
                                onClick={() => {
                                    handleTjenesteChanged('');
                                    setKunUleverte(false);
                                    setStartDate(null);
                                }}
                            >
                                Nullstill filter
                            </IconButton>
                        </FormGroup>

                        <FormGroup className="p-3">
                            <Form.Label className='bigLabel'>Tjeneste</Form.Label>
                            <TjenestePicker selectedSource={tjeneste} onChange={handleTjenesteChanged} />
                        </FormGroup>

                        <FormGroup className={`p-3 datePickerWrapper`}>
                            <Form.Label className='bigLabel'>Eldre enn</Form.Label>
                            <DatePicker
                                className={`border-1 p-2 rounded-1 border ${['my-custom-border']} ${['widerSize']}`}
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                showTimeSelect
                                timeFormat="HH:mm:ss"
                                timeIntervals={15}
                                timeCaption="Tidspunkt"
                                dateFormat="dd.MM.yyyy HH:mm:ss"
                                placeholderText="Velg tidspunkt"
                            />
                        </FormGroup>

                        <FormGroup className="p-3">
                            <Form.Label className='bigLabel'>Kun uleverte</Form.Label>
                            <Form.Check
                                type="checkbox"
                                checked={kunUleverte}
                                onChange={() => setKunUleverte(!kunUleverte)}
                            />
                        </FormGroup>
                    </div>
                </Form>
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default FilterWebhookEventLog;