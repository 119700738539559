import SupernovaApi from "api/SupernovaApi";
import { LogEntry } from "api/SupernovaApi/models";
import EventLogTable from "components/EventLogTable";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDebounce } from "utils/hooks/useDebounce";
import FilterEventLog from "./FilterEventlog";
import { IconButton } from "@tradesolution/iceberg-ui-react";
import IcebergBadge from "components/IcebergBadge";
import CommonLoader from "components/CommonLoader";


const EventLog = () => {

    const [logEntries, setLogEntries] = useState<LogEntry[]>([]);

    const [q, setQ] = useState<string>("");
    const debouncedQ = useDebounce<string>(q, 500)

    const [tjeneste, setTjeneste] = useState<string>("");
    const [kunUleverte, setKunUleverte] = useState<boolean>(false);
    const take = 40;
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean | null>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [showFilter, setShowFilter] = useState(false);
    const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);

    useEffect(() => {
        const load = async () => {
            setIsLoading(page === 1);
            setIsFetchingMore(page > 1);
            try {
                const s = await SupernovaApi.getEventLog(tjeneste, debouncedQ, kunUleverte, startDate, take * page);
                if (page === 1) {
                    setLogEntries(s);
                } else {
                    setLogEntries(prev => [...prev, ...s.slice(prev.length)]);
                }
                setHasMore(s.length >= (take * page));
            } catch (error) {
                console.error('Feil ved innlasting av logger:', error);
            }
            setIsLoading(false);
            setIsFetchingMore(false);
        };
        load();
    }, [tjeneste, debouncedQ, kunUleverte, startDate, page]);

    useEffect(() => {
        setPage(1);
    }, [tjeneste, debouncedQ, kunUleverte, startDate]);

    const handleTjenesteChanged = (source: string): void => {
        setTjeneste(source);
    }

    const [activeFilters, setActiveFilters] = useState<{ label: string, removeAction: () => void }[]>([]);
    const formatDate = (date) => {
        return date.toLocaleString('no-NO', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).replace(/,/g, '');
    };

    useEffect(() => {
        let newFilters: { label: string, removeAction: () => void }[] = [];
        if (tjeneste) {
            newFilters.push({ label: `${tjeneste}`, removeAction: () => setTjeneste("") });
        }
        if (startDate) {
            newFilters.push({ label: formatDate(startDate), removeAction: () => setStartDate(null) });
        }
        if (kunUleverte) {
            newFilters.push({ label: 'Kun Uleverte', removeAction: () => setKunUleverte(false) });
        }
        setActiveFilters(newFilters);
    }, [tjeneste, startDate, kunUleverte]);

    const removeFilter = (filter: string) => {
        const filterObject = activeFilters.find(f => f.label === filter);
        if (filterObject) {
            filterObject.removeAction();
        }
        setActiveFilters(prevFilters => prevFilters.filter(f => f.label !== filter));
    };
    
    return (
        <PageWrapper title="Event log">
            <Row className="mb-3">
                <Col>
                    <Form.Control
                        type="text" placeholder="Søk på event, GTIN, GLN, epdnr, oppsumeringmelding eller eventypenavn"
                        value={q}
                        onChange={(e) => setQ(e.target.value)}
                    />
                </Col>
                <Col>
                    <IconButton
                        variant="outline-primary"
                        icon="filter"
                        onClick={() => setShowFilter(!showFilter)}
                    >
                        Filter
                    </IconButton>
                    <FilterEventLog
                        tjeneste={tjeneste}
                        handleTjenesteChanged={handleTjenesteChanged}
                        setKunUleverte={setKunUleverte}
                        kunUleverte={kunUleverte}
                        setStartDate={setStartDate}
                        startDate={startDate}
                        show={showFilter}
                        onClose={() => setShowFilter(false)}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    {activeFilters.length > 0 && (
                        <div
                            onClick={() => {
                                handleTjenesteChanged('');
                                setKunUleverte(false);
                                setStartDate(null);
                            }}
                            style={{ cursor: 'pointer', display: 'inline-flex', verticalAlign: 'middle' }}
                        >
                            <IcebergBadge variant="primary" style={{ verticalAlign: 'middle' }}>
                                <span style={{ verticalAlign: 'middle' }}>Nullstill</span>
                                <Button
                                    size="sm"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleTjenesteChanged('');
                                        setKunUleverte(false);
                                        setStartDate(null);
                                    }}
                                    style={{
                                        marginLeft: '4px',
                                        padding: '0',
                                        verticalAlign: 'middle',
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                        backgroundColor: 'transparent',
                                        borderColor: 'transparent',
                                        color: 'inherit'
                                    }}
                                >
                                    x
                                </Button>
                            </IcebergBadge>
                        </div>
                    )}
                    {activeFilters.map((filter, index) => (
                        <div
                            key={index}
                            onClick={() => removeFilter(filter.label)}
                            style={{ cursor: 'pointer', display: 'inline-flex', verticalAlign: 'middle', margin: '2px' }}
                        >
                            <IcebergBadge variant="light" style={{ verticalAlign: 'middle' }}>
                                <span style={{ verticalAlign: 'middle' }}>
                                    {filter.label}
                                </span>
                                <Button
                                    size="sm"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        removeFilter(filter.label);
                                    }}
                                    style={{
                                        marginLeft: '4px',
                                        padding: '0',
                                        verticalAlign: 'middle',
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                        backgroundColor: 'transparent',
                                        borderColor: 'transparent',
                                        color: 'inherit'
                                    }}
                                >
                                    x
                                </Button>
                            </IcebergBadge>
                        </div>
                    ))}
                </Col>
            </Row>

            {isLoading ? null : (
                <>
                    {logEntries.length === 0 && (
                        <Row>
                            <Col>
                                <p>Ingen logger hittil!</p>
                            </Col>
                        </Row>
                    )}
                    {logEntries.length > 0 && (
                        <Row>
                            <Col>
                                <EventLogTable logEntries={logEntries} />
                            </Col>
                        </Row>
                    )}
                </>
            )}
            <Row className="p-3">
                <Col style={{ textAlign: 'center' }}>
                    {isLoading && !isFetchingMore && (
                        <CommonLoader text="Henter..." />
                    )}
                    {isFetchingMore && (
                        <CommonLoader text="Henter mer..." />
                    )}
                    {!isLoading && !isFetchingMore && hasMore && (
                        <button type="button" className="btn btn-outline-primary"
                            onClick={() => setPage(page + 1)}>Last flere</button>
                    )}
                </Col>
            </Row>
        </PageWrapper>
    );
};

export default EventLog;