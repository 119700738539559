import { IconButton } from "@tradesolution/iceberg-ui-react";
import { Col, Form, Row } from "react-bootstrap";

interface Props {
    emails: string[];
    onChange: (emails: string[]) => void;
}

const ContactEmailEditor = (props: Props) => {

    const add = () => {
        props.onChange([...props.emails, ""]);
    };

    const update = (index: number, value: string) => {
        const emails = [...props.emails];
        emails[index] = value;
        props.onChange(emails);
    };

    const remove = (index: number) => {
        const emails = [...props.emails];
        emails.splice(index, 1);
        props.onChange(emails);
    };

    return (
        <Form.Group className="mb-3">
            <Form.Label>Kontakt epost. Brukes for å informere om feil. Vi bruker din e-post hvis ikke noe legges inn</Form.Label>
            {props.emails.map((email, index) => (
                <Row key={index} className="mb-2">
                    <Col>
                        <Form.Label>Epost</Form.Label>
                        <Form.Control type="text" name="value" value={email} onChange={e => update(index, e.target.value)} />
                    </Col>
                    <Col md={3}>
                        <Form.Label>&nbsp;</Form.Label><br></br>
                        <IconButton icon="trash" size="sm" variant="outline-danger" title="Fjern" onClick={() => remove(index)}></IconButton>
                    </Col>
                </Row>
            )
            )}
            <div>
                <IconButton icon="plus" size="sm" variant="outline-primary" onClick={add}>Legg til</IconButton>
            </div>
        </Form.Group>
    )
};

export default ContactEmailEditor;