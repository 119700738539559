import SupernovaApi from "api/SupernovaApi";
import { PersonalLogEntry } from "api/SupernovaApi/models";
import CommonLoader from "components/CommonLoader";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";

interface Props {
    personalSubscriberId: string;
}

const PersonalSubscriberEventLog = (props: Props) => {
    const pageSize = 40;
    const [page, setPage] = useState(1);

    const [logEntries, setLogEntries] = useState<PersonalLogEntry[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean | null>(null);
    const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);

    useEffect(() => {
        const load = async () => {
            setLoading(page === 1);
            setIsFetchingMore(page > 1);
            try {
                const s = await SupernovaApi.profile.getEventLog(props.personalSubscriberId, pageSize * page);
                if (page === 1) {
                    setLogEntries(s);
                } else {
                    setLogEntries(prev => [...prev, ...s.slice(prev.length)])
                }
                setHasMore(s.length >= (pageSize * page));
            } catch (error) {
                console.error('Feil ved innlasting av logger:', error);
            } finally {
                setLoading(false);
                setIsFetchingMore(false);
            }
        };

        load();

    }, [props.personalSubscriberId, page]);

    return (
        <>
            {
                loading ? null : (
                    <>
                        {logEntries.length === 0 && (<p>Ingen meldinger sendt!</p>)}
                        {logEntries.length > 0 && (
                            <Table className="tsTable" hover responsive>
                                <thead>
                                    <tr>
                                        <th>Dato</th>
                                        <th>Hendelse</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logEntries.map((x, i) => (
                                        <tr key={i}>
                                            <td>
                                                {format(x.triggeredAt, 'dd.MM.yy')}<br />
                                                {format(x.triggeredAt, 'HH:mm')}
                                            </td>
                                            <td>
                                                {x.userInterfaceUrl ? (
                                                    <a
                                                        href={x.userInterfaceUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <strong style={{ fontWeight: 600 }}>{x.eventType.displayName} </strong> - {x.source}
                                                    </a>
                                                ) : (
                                                    <>
                                                        <strong style={{ fontWeight: 600 }}>{x.eventType.displayName}</strong> - {x.source}
                                                    </>

                                                )}
                                                <br />
                                                {x.displayMessage}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </>
                )}

            <Row className="p-3">
                <Col style={{ textAlign: 'center' }}>
                    {loading && !isFetchingMore && (
                        <CommonLoader text="Henter..." />
                    )}
                    {isFetchingMore && (
                        <CommonLoader text="Henter mer..."  />
                    )}
                    {!loading && !isFetchingMore && hasMore && (
                        <button type="button" className="btn btn-outline-primary"
                            onClick={() => setPage(page + 1)}>Last flere</button>
                    )}
                </Col>
            </Row>
        </>
    )

}

export default PersonalSubscriberEventLog;