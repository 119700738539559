import { IconButton } from "@tradesolution/iceberg-ui-react";
import SupernovaApi, { ApiResult } from "api/SupernovaApi";
import { EventType, Webhook } from "api/SupernovaApi/models";
import ApiFeedback from "components/ApiFeedback";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";

interface Props {
    show: boolean;
    webhook?: Webhook;
    eventType?: EventType;
    onClose: () => void;
}

const TestModal = (props: Props) => {

    const handleClose = () => {
        setSelectedEventType(undefined);
        setApiResult(undefined);
        props.onClose();
    };

    const selectableEventTypeIds = props.webhook?.subscribedToEventTypeIds ?? [];

    const [eventTypes, setEventTypes] = useState<EventType[]>([]);

    const loadEventTypes = async () => {
        const et = await SupernovaApi.eventTypes.getEventTypes();
        if (selectableEventTypeIds) {
            setEventTypes(et.filter(x => selectableEventTypeIds.includes(x.id)));
        }
        else {
            setEventTypes(et);
        }
    };

    useEffect(() => {
        loadEventTypes();
    }, [props.webhook?.id, props.eventType?.id]);

    useEffect(() => {
        setSelectedEventType(props.eventType);
    }, [props.eventType])

    const [selectedEventType, setSelectedEventType] = useState<EventType | undefined>();
    const [payload, setPayload] = useState<string>("");
    const [displayMessage, setDisplayMessage] = useState<string>("Test webhook message");

    const handleEventTypesSelected = (eventTypeId: string): void => {
        const selected = eventTypes.length > 0 ? eventTypes.find(o => o.id === eventTypeId) : undefined;
        setSelectedEventType(selected);
        setPayload(selected?.payload);
        setApiResult(undefined);
    }

    const submitDisabled: boolean = !selectedEventType;

    const [apiResult, setApiResult] = useState<ApiResult<any>>(undefined);
    const handleSend = async () => {
        const result = await SupernovaApi.webhooks.test(props.webhook?.id, selectedEventType.id, JSON.parse(payload), displayMessage);
        setApiResult(result);
    }

    return (
        <Modal show={props.show && !!props.webhook} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Test webhook</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Velg hendelsestype du ønsker å teste. OBS: Du må abonnere på denne hendelsen for at du skal motta den. En melding av denne typen blir da sendt til registrert URI</p>
                <Form>
                    <Form.Group>
                        <Form.Label>Hvilket event ønsker du å teste?</Form.Label>
                        <Form.Select value={selectedEventType?.id} onChange={e => handleEventTypesSelected(e.target.value)}>
                            <option>Velg event</option>
                            {eventTypes.map(x => <option key={x.id} value={x.id}>{x.displayName} - {x.source}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Payload</Form.Label>
                        <Form.Control as="textarea" rows={10} value={payload} onChange={e => setPayload(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Visning melding</Form.Label>
                        <Form.Control
                            type="text"
                            name="displayMessage"
                            value={displayMessage}
                            onChange={e => setDisplayMessage(e.target.value)}
                        />
                    </Form.Group>
                    <ApiFeedback apiResult={apiResult} successMessage="Melding er sendt!" errorMessage="Noe gikk galt!" />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <IconButton icon="close" onClick={handleClose} className="col" variant="outline-primary">Avbryt</IconButton>
                <IconButton icon="send" disabled={submitDisabled} onClick={handleSend} className="col" variant="primary">Send</IconButton>
            </Modal.Footer>
        </Modal>
    );
}

export default TestModal;