import { IconButton } from "@tradesolution/iceberg-ui-react";
import SupernovaApi from "api/SupernovaApi";
import { EventType, ProfileData, SubscriptionMethod, WebhookSubscriber } from "api/SupernovaApi/models";
import PageWrapper from "components/PageWrapper";
import useToaster from "framework/Toaster/useToaster";
import UrlHelper from "framework/UrlHelper";
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

interface Props {

}

const Subscribers = (props: Props) => {
    const { id } = useParams();

    const toaster = useToaster();

    const [webhookSubscribers, setWebhookSubscribers] = useState<WebhookSubscriber[]>([]);
    const [personalSubscribers, setPersonalSubscribers] = useState<ProfileData[]>([]);
    const [personalSubscribersCount, setPersonalSubscribersCount] = useState<number>(0);
    const [eventType, setEventType] = useState<EventType | undefined>();

    const loadWebhookSubscribers = async () => {
        const result = await SupernovaApi.getWebhookSubscriberForEventtype(id);
        setWebhookSubscribers(result);
    };

    const loadPersonalSubscribers = async () => {
        const result = await SupernovaApi.getPersonalSubscriberForEventtype(id);
        setPersonalSubscribers(result);
    };

    const loadPersonalSubscribersCount = async () => {
            const result = await SupernovaApi.getPersonalSubscriberForEventtypeCount(id);
            setPersonalSubscribersCount(result);
    };
    useEffect(() => {
        loadWebhookSubscribers();
        loadPersonalSubscribers();
        loadPersonalSubscribersCount();

        const loadEventType = async () => {
            const et = await SupernovaApi.eventTypes.getEventType(id);
            setEventType(et);
        };
        loadEventType();
    }, []);

    const removeWebhookSubscriber = async (webhookId: string) => {
        const response = await SupernovaApi.webhooks.unsubscribeToEventType(webhookId, id);
        if (response.success) {
            toaster.success('Abonnent fjernet');
        } else {
            toaster.error('Noe gikk galt');
        }
        loadWebhookSubscribers();
    };

    const removePersonalSubscriber = async (personalSubscriberId: string) => {
        const response = await SupernovaApi.profile.unSubscribeFromEvent(personalSubscriberId, id, SubscriptionMethod.Web);
        if (response.success) {
            toaster.success('Abonnent fjernet');
        } else {
            toaster.error('Noe gikk galt');
        }
        loadPersonalSubscribers();
    };

    return (
        <PageWrapper title={`Abonnenter av ${eventType?.displayName}`}>
            <Row className="mt-3">
                <Col>
                    <h3>Webhooks ({webhookSubscribers.length})</h3>
                    {webhookSubscribers.length === 0 && <p>Ingen webhook abonnenter</p>}
                    {webhookSubscribers.length > 0 &&
                        <Table className="tsTable" hover>
                            <tbody>
                                {webhookSubscribers.map(x => (
                                    <tr key={x.id}>
                                        <td>
                                            <strong style={{ fontWeight: 600 }}>{x.name}</strong><br />
                                            <span title={x.webhookUri} style={{ fontSize: "smaller" }}>{UrlHelper.shortUrl(x.webhookUri, 100)}</span>
                                        </td>
                                        <td className="fitContent">
                                            <IconButton icon="trash" variant="outline-danger" title="Fjern abonnement" onClick={() => removeWebhookSubscriber(x.id)} />
                                        </td>
                                    </tr>
                                )
                                )}
                            </tbody>
                        </Table>
                    }
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <h3>Personlige ({personalSubscribersCount})</h3>
                    {personalSubscribers.length === 0 && <p>Ingen personlige abonnenter</p>}
                    {personalSubscribers.length > 0 &&
                        <Table className="tsTable" hover>
                            <tbody>
                                {personalSubscribers.map(x => (
                                    <tr key={x.id}>
                                        <td>
                                            <strong style={{ fontWeight: 600 }}>{x.name}</strong><br />
                                            {x.tsKundeName}
                                        </td>
                                        <td className="fitContent">
                                            <IconButton icon="trash" variant="outline-danger" title="Fjern abonnement" onClick={() => removePersonalSubscriber(x.id)} />
                                        </td>
                                    </tr>
                                )
                                )}
                            </tbody>
                        </Table>
                    }
                </Col>
            </Row>

        </PageWrapper>
    )
}

export default Subscribers;