import { useContext } from 'react';
import { ToastContext } from './ToastProvider';

export default function useToaster() {
    const toasterContext = useContext<any>(ToastContext);

    return {
        info: (message: string) => {
            toasterContext.info(message);
        },
        success: (message: string) => {
            toasterContext.success(message);
        },
        error: (message: string | { key: string; errors: string[] }[], callback: void) => {
            toasterContext.error(message, callback);
        },
        processing: (message: string, onCancel: () => void) => {
            toasterContext.processing(message, onCancel);
        }
    };
}