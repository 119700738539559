import { useMsal, useAccount } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import axios from 'axios';
import { parseISO } from 'date-fns';

interface Props {
    children: JSX.Element,
}

class DateHandler {
    // https://stackoverflow.com/questions/3143070/javascript-regex-iso-datetime
    // NB: This is not a full match regex as it quickly becomes complex with various iso-date formats
    static isoDateFormat = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;

    static isIsoDateString = (value: any): boolean => {
        return value && typeof value === "string" && value.length < 40 && DateHandler.isoDateFormat.test(value);
    }

    static convertIsoStringsToJsDates = (body: any) => {
        if (body === null || body === undefined || typeof body !== "object")
            return body;

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (DateHandler.isIsoDateString(value)) body[key] = parseISO(value);
            else if (typeof value === "object") DateHandler.convertIsoStringsToJsDates(value);
        }
    }
}

// Ensures that a valid access token is attached to all outgoing requests
const EnsureAuthToken = (props: Props) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    /* eslint-disable no-param-reassign */
    axios.interceptors.request.use(async (config) => {
        if (!account) {
            throw Error('No active account! Verify a user has been signed in.');
        }

        const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account,
        });

        const bearer = `Bearer ${response.accessToken}`;
        config.headers.Authorization = bearer;

        return config;
    });
    /* eslint-enable no-param-reassign */

    // convert all json iso-strings to js dates
    axios.interceptors.response.use(originalResponse => {
        DateHandler.convertIsoStringsToJsDates(originalResponse.data);
        return originalResponse;
    });

    return (
        <>
            {props.children}
        </>
    );
};

export default EnsureAuthToken;