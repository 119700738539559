import React from "react";
import { Badge } from "react-bootstrap";

interface Props {
    variant?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
    onClick?: (e: any) => void;
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const IcebergBadge = (props: Props) => {
    const getBg = () => {
        switch (props.variant) {
            case "primary":
                return "primary-subtle";
            case "secondary":
                return "secondary-subtle";
            case "success":
                return "success-subtle";
            case "danger":
                return "danger-subtle";
            case "warning":
                return "warning-subtle";
            case "info":
                return "info-subtle";
            case "light":
                return "light";
            case "dark":
                return "dark-subtle";
            default:
                return "primary-subtle";
        }
    }

    const getTextClass = () => {
        switch (props.variant) {
            case "primary":
                return "text-primary";
            case "secondary":
                return "text-light";
            case "success":
                return "text-success";
            case "danger":
                return "text-danger";
            case "warning":
                return "text-warning-dark";
            case "info":
                return "text-info";
            case "light":
                return "text-dark";
            case "dark":
                return "text-light";
            default:
                return "text-primary";
        }
    }

    return (
        <Badge bg={getBg()} className={getTextClass()} onClick={props.onClick} style={{...props.style}}>
            {props.children}
        </Badge>
    )
}

export default IcebergBadge;