import { IconButton, useSelectedTsKunde } from "@tradesolution/iceberg-ui-react";
import { CreateWebhookCommand, TsKunde, Webhook } from "api/SupernovaApi/models";
import { Form, Modal } from "react-bootstrap";
import useForm from "utils/hooks/useForm";
import CustomHeaderEditor from "../CustomHeaderEditor";
import ContactEmailEditor from "../ContactEmailEditor";
import { useState } from "react";
import SupernovaApi, { ApiResult } from "api/SupernovaApi";
import TsKundeTypeahead from "components/TsKundeTypeahead";
import ApiFeedback from "components/ApiFeedback";

interface Props {
    show: boolean;
    isAdmin: boolean;
    onClose: () => void;
}

const AddModal = (props: Props) => {

    const { selectedTsKunde } = useSelectedTsKunde();

    const defaultValues = {
        name: '',
        webhookUri: '',
        tsKundeId: selectedTsKunde?.tsKundeId,
        customHeaders: [],
        contactEmails: [],
    };

    const validate = (values: CreateWebhookCommand) => {
        const errors: any = {};
        if (!values.name) errors.name = "Navn må fylles ut";
        if (!values.webhookUri) errors.webhookUri = "Webhook url må fylles ut";
        if (!values.tsKundeId) errors.webhookUri = "Kunde mangler";
        return errors;
    };

    const [apiResult, setApiResult] = useState<ApiResult<Webhook> | undefined>(undefined);

    const handleSubmit = async (command: CreateWebhookCommand): Promise<ApiResult<Webhook>> => {
        const result = await SupernovaApi.webhooks.addWebhook(command);
        setApiResult(result);
        return result;
    };

    const { values, handleChange, resetForm, errors, touched, submitDisabled } = useForm<CreateWebhookCommand, ApiResult<Webhook>>(defaultValues, validate, handleSubmit);

    const handleClose = () => {
        resetForm(defaultValues);
        setApiResult(undefined);
        setAdminSelectedTsKunde(undefined);
        props.onClose();
    };

    const [adminSelectedTsKunde, setAdminSelectedTsKunde] = useState<TsKunde | undefined>(undefined);

    const handleTsKundeSelected = (kunde?: TsKunde): void => {
        if (kunde) {
            setAdminSelectedTsKunde(kunde);
            handleChange("tsKundeId", kunde.id);
        }
    }

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Registrer ny webhook</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Navn</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={e => handleChange("name", e.target.value)}
                            isInvalid={!!errors.name && touched.name}
                            isValid={!!values.name && !errors.name}
                        />
                        <Form.Control.Feedback type="invalid">{errors?.name}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Webhook url</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={values.webhookUri}
                            onChange={e => handleChange("webhookUri", e.target.value)}
                            isInvalid={!!errors.webhookUri && touched.webhookUri}
                            isValid={!!values.webhookUri && !errors.webhookUri}
                        />
                        <Form.Control.Feedback type="invalid">{errors?.webhookUri}</Form.Control.Feedback>
                    </Form.Group>
                    {props.isAdmin && <Form.Group>
                        <Form.Label>Kunde</Form.Label>
                        <TsKundeTypeahead onChange={handleTsKundeSelected} selected={adminSelectedTsKunde} />
                    </Form.Group>}
                    <CustomHeaderEditor customHeaders={values.customHeaders} onChange={e => handleChange("customHeaders", e)} />
                    <ContactEmailEditor emails={values.contactEmails} onChange={e => handleChange("contactEmails", e)} />
                </Form>
                <ApiFeedback apiResult={apiResult} successMessage="Webhook registrert!" errorMessage="En feil oppsto!" />
            </Modal.Body>
            <Modal.Footer>
                {!!apiResult && <IconButton className="col" icon="close" variant="outline-primary" onClick={handleClose}>Lukk</IconButton>}
                {!apiResult && <>
                    <IconButton className="col" icon="close" variant="outline-primary" onClick={handleClose}>Avbryt</IconButton>
                    <IconButton disabled={submitDisabled} className="col" icon="disk" onClick={() => handleSubmit(values)}>Lagre</IconButton>
                </>}
            </Modal.Footer>
        </Modal>
    )
}

export default AddModal;