import { IconButton } from "@tradesolution/iceberg-ui-react";
import SupernovaApi, { ApiResult } from "api/SupernovaApi";
import { EventType, Webhook } from "api/SupernovaApi/models";
import ApiFeedback from "components/ApiFeedback";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import EventTypePickerLargeForWebhook from "./EventyTypePickerLargeForWebhook";

interface Props {
    show: boolean;
    webhook?: Webhook;
    onClose: () => void;
}

const SubscribeModal = (props: Props) => {

    const [eventTypes, setEventTypes] = useState<EventType[]>([]);

    const loadEventTypes = async () => {
        const et = await SupernovaApi.eventTypes.getEventTypes();
        setEventTypes(et);
    };

    useEffect(() => {
        loadEventTypes();
    }, []);

    const handleClose = () => {
        setApiResult(undefined);
        props.onClose();
    };

    const [selectedEventTypes, setSelectedEventTypes] = useState<EventType[]>([]);

    useEffect(() => {
        setSelectedEventTypes(eventTypes.filter(x => (props.webhook?.subscribedToEventTypeIds ?? []).includes(x.id)));
    }, [props.webhook, eventTypes]);

    const handleEventTypesSelected = (eventTypes: EventType[]): void => {
        setSelectedEventTypes(eventTypes);        
        setApiResult(undefined);
    }

    const [apiResult, setApiResult] = useState<ApiResult<any>>(undefined);
    const handleSave = async () => {
        if (selectedEventTypes.length > 0) {
            const result = await SupernovaApi.webhooks.subscribeToEventTypes(props.webhook?.id, selectedEventTypes.map(x => x.id));
            setApiResult(result);
        } 
    }

    return (
        <Modal size="lg" show={props.show && !!props.webhook} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Abonner på hendelser</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Velg hendelser du ønsker tilsendt til din webhook
                </p>
                <Form>
                    <EventTypePickerLargeForWebhook
                        onEventTypesSelected={handleEventTypesSelected}
                        alreadySelectedEventTypeIds={props.webhook?.subscribedToEventTypeIds || []}
                    />
                    <ApiFeedback apiResult={apiResult} successMessage="Abonnement oppdatert!" errorMessage="En feil oppsto!" />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {apiResult?.success ?
                    <IconButton icon="close" onClick={handleClose} className="col" variant="outline-primary">Lukk</IconButton> :
                    <>
                        <IconButton icon="close" onClick={handleClose} className="col" variant="outline-primary">Avbryt</IconButton>
                        <IconButton icon="disk" onClick={handleSave} className="col" variant="primary">Lagre</IconButton>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default SubscribeModal;