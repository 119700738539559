import { IconButton, useSelectedTsKunde } from "@tradesolution/iceberg-ui-react";
import SupernovaApi from "api/SupernovaApi";
import { ProfileData, SubscribedEventType, SubscriptionMethod } from "api/SupernovaApi/models";
import PageWrapper from "components/PageWrapper";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { Accordion, Card, Col, Form, Row, Table } from "react-bootstrap";
import useToaster from "framework/Toaster/useToaster";
import * as _ from 'lodash';
import SubscribeModal from "./SubscribeModal";
import ProfileModal from "./ProfileModal";
import { useParams } from "react-router-dom";
import PersonalSubscriberEventLog from "components/PersonalSubscriberEventLog";
import PersonalSubscriberAuditLog from "components/PersonalSubscriberAuditLog";
import ConfirmButton from "components/ConfirmButton";

const VarslingsInnstillinger = () => {

    const { id } = useParams();
    const isAdminModus = !!id;

    const { selectedTsKunde } = useSelectedTsKunde();
    const toaster = useToaster();

    const [profile, setProfile] = useState<ProfileData | undefined>();

    const [reloadAuditlog, setReloadAuditlog] = useState(false);

    const loadProfile = useCallback(async () => {
        let profileData: SetStateAction<ProfileData>;
        if (isAdminModus) {
            profileData = await SupernovaApi.profile.getById(id);
        } else if (selectedTsKunde) {
            profileData = await SupernovaApi.profile.getByTsKunde(selectedTsKunde.tsKundeId);
        }
        setProfile(profileData);

    }, [id, isAdminModus, selectedTsKunde]);

    useEffect(() => {
        if (selectedTsKunde) {
            loadProfile();
        }
    }, [selectedTsKunde, loadProfile]);

    const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);

    const handleCloseAddModal = () => {
        setShowAddEventModal(false);
        setReloadAuditlog(!reloadAuditlog);
        loadProfile();
    }

    const [showProfileModal, setShowProfileModal] = useState<boolean>(false);

    const profileModalData = {
        id: profile?.id,
        name: profile?.name,
        emailAddress: profile?.emailAddress,
        mobileNumber: profile?.phoneNumber
    }

    // du har en epost og det er ikke en trades.no epost adresse
    const emailEnabled = profile?.emailAddress && !profile?.emailAddress.includes('@trades.no');

    const toggleSubscribeByEmail = async (eventType: SubscribedEventType): Promise<void> => {
        let result;
        if (eventType.delayedEmailDelivery) {
            result = await SupernovaApi.profile.unSubscribeFromEvent(profile?.id, eventType.id, SubscriptionMethod.Email);
        } else {
            if (!emailEnabled) {
                toaster.error('Du må ha en gyldig epost adresse for å kunne abonnere på epost-varsler');
            } else {
                result = await SupernovaApi.profile.subscribeToEvent(profile?.id, eventType.id, SubscriptionMethod.Email);
            }
        }

        if (result.success) {
            toaster.success('Varslingsinnstillinger oppdatert');
        } else {
            toaster.error('Kunne ikke oppdatere varslingsinnstillinger');
        }

        await loadProfile();
    }

    const toggleSubscribeByEmailImidiate = async (eventType: SubscribedEventType): Promise<void> => {
        let result;
        if (eventType.imidiateEmailDelivery) {
            result = await SupernovaApi.profile.unSubscribeFromEvent(profile?.id, eventType.id, SubscriptionMethod.EmailImidiate);
        } else {
            if (!emailEnabled) {
                toaster.error('Du må ha en gyldig epost adresse for å kunne abonnere på epost-varsler');
            } else {
                result = await SupernovaApi.profile.subscribeToEvent(profile?.id, eventType.id, SubscriptionMethod.EmailImidiate);
            }
        }

        if (result.success) {
            toaster.success('Varslingsinnstillinger oppdatert');
        } else {
            toaster.error('Kunne ikke oppdatere varslingsinnstillinger');
        }

        await loadProfile();
    }

    const toggleSubscribeBySms = async (eventType: SubscribedEventType): Promise<void> => {
        let result;
        if (eventType.smsDelivery) {
            result = await SupernovaApi.profile.unSubscribeFromEvent(profile?.id, eventType.id, SubscriptionMethod.Sms);
        } else {
            if (!profile?.phoneNumber) {
                toaster.error('Du må legge til et mobilnummer før du kan abonnere på SMS-varsler');
            } else {
                result = await SupernovaApi.profile.subscribeToEvent(profile?.id, eventType.id, SubscriptionMethod.Sms);
            }
        }

        if (result.success) {
            toaster.success('Varslingsinnstillinger oppdatert');
        } else {
            toaster.error('Kunne ikke oppdatere varslingsinnstillinger');
        }

        await loadProfile();
    }

    const handleProfileModalClose = (): void => {
        setShowProfileModal(false);
        loadProfile();
    }

    const [selectedEventTypeIds, setSelectedEventTypeIds] = useState<string[]>([]);

    const toggleEventType = (id: string): void => {
        if (selectedEventTypeIds.includes(id)) {
            setSelectedEventTypeIds(selectedEventTypeIds.filter(i => i !== id));
        } else {
            setSelectedEventTypeIds([...selectedEventTypeIds, id]);
        }
    }

    const getFilteredEventTypesBySource = (source: string): SubscribedEventType[] => {
        return profile?.subscribedEventTypes.filter(o => o.source === source) || [];
    }

    const getUniquSources = (): string[] => {
        return _.uniq(profile?.subscribedEventTypes.map(et => et.source).sort(((a, b) => a.localeCompare(b))) || []);
    }

    const toggleAllFilteredEventTypes = (source: string): void => {
        if (selectedEventTypeIds.length === getFilteredEventTypesBySource(source).length) {
            setSelectedEventTypeIds([]);
        } else {
            setSelectedEventTypeIds(getFilteredEventTypesBySource(source).map(et => et.id));
        }
    }

    const handleRemoveSelectedEventTypes = async (): Promise<void> => {
        const result = await SupernovaApi.profile.unSubscribeFromEvents(profile?.id, selectedEventTypeIds);
        if (result.success) {
            toaster.success('Varslingsinnstillinger oppdatert');
        } else {
            toaster.error('Kunne ikke oppdatere varslingsinnstillinger');
        }

        setSelectedEventTypeIds([]);
        setReloadAuditlog(!reloadAuditlog);
        await loadProfile();
    }

    const handleRemoveAllNotifications = async (): Promise<void> => {
        const result = await SupernovaApi.profile.unSubscribeFromAllEvents({ tsKundeId: profile?.tsKundeId });
        if (result.success) {
            toaster.success('Alle varsler fjernet');
        } else {
            toaster.error('Vi prøvde så godt vi kunne men alle varsler ble ikke fjernet');
        }

        setReloadAuditlog(!reloadAuditlog);
        await loadProfile();
    }

    const getKontaktInfoText = (): string => {
        const email = profile?.emailAddress;
        const sms = profile?.phoneNumber;

        if (!email && !sms) {
            return 'Du mottar kun varsler på nettsiden, legg inn epost og/eller sms for å motta varsler på disse kanalene';
        }

        if (email && !sms) {
            return `Du mottar varsler på ${email}. Registrer mobilnummer for å motta varsler på sms`;
        }

        return `Du mottar varsler på ${profile?.emailAddress} og på sms til ${profile?.phoneNumber}`;
    };

    return (
        <PageWrapper
            title={id ? `Varslingsinnstillinger for ${profile?.name} (${profile?.tsKundeName})` : "Mine varslingsinnstillinger"}
        >
            <Card className="mt-3 mb-3">
                <Card.Body>
                    <Row>
                        <Col md={8}>
                            <p className="info-text">
                                {getKontaktInfoText()}
                            </p>
                        </Col>
                        <Col className="text-end">
                            <span><IconButton icon="profile" size="sm" variant="outline-primary" onClick={() => setShowProfileModal(true)}>Rediger kontaktinformasjon</IconButton></span>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <Row className="mb-3 mt-3">
                        <Col>
                            <Card.Title>Du mottar {profile?.subscribedEventTypes?.length} {profile?.subscribedEventTypes?.length > 1 ? 'varsler' : 'varsel'} fra Tradesolution</Card.Title>
                        </Col>
                        <Col className="text-end">
                            {profile?.subscribedEventTypes?.length > 0 ? (
                                <>
                                    <ConfirmButton icon="minus" style={{ marginRight: "0.5rem" }} variant="outline-primary" disabled={profile?.subscribedEventTypes?.length === 0} onClick={handleRemoveAllNotifications}>
                                        Fjern alle varsler
                                    </ConfirmButton>
                                    <IconButton icon="plus" variant="primary" onClick={() => setShowAddEventModal(true)}>Abonner på flere varsler</IconButton>
                                </>
                            ) : (
                                <IconButton
                                    icon="plus"
                                    variant="primary"
                                    onClick={() => setShowAddEventModal(true)}>Velg varsler du ønsker å motta
                                </IconButton>
                            )}
                        </Col>
                    </Row>

                    {profile && profile.subscribedEventTypes?.length > 0 &&
                        <Accordion defaultActiveKey="0">
                            {getUniquSources().map(source => (
                                <Accordion.Item eventKey={source} key={source}>
                                    <Accordion.Header>
                                        {getFilteredEventTypesBySource(source).length} {getFilteredEventTypesBySource(source).length === 1 ? "varsel" : "varsler"} fra {source}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="mt-3 mb-3">
                                            <Col style={{ textAlign: "right" }}>
                                                <IconButton icon="minus" style={{ marginRight: "0.5rem" }} variant="outline-primary" disabled={selectedEventTypeIds.length < 1} onClick={handleRemoveSelectedEventTypes}>
                                                    Fjern valgte varsler
                                                </IconButton>
                                            </Col>
                                        </Row>
                                        <Table className="tsTable" hover>
                                            <thead>
                                                <tr>
                                                    <th>Hendelse</th>
                                                    <th className="text-center">
                                                        <span title="Varsler samles opp og sendes 1 gang hver andre time">Epost oppsamlet</span>
                                                    </th>
                                                    <th className="text-center"><span title="Du mottar epost så snart hendelsen oppstår">Epost umiddelbart</span></th>
                                                    <th className="text-center"><span title="Du mottar sms så snart hendelsen oppstår">SMS</span></th>
                                                    <th className="fitContent">
                                                        <Form.Check
                                                            checked={getFilteredEventTypesBySource(source).length === selectedEventTypeIds.length}
                                                            onChange={() => toggleAllFilteredEventTypes(source)}
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getFilteredEventTypesBySource(source).map((eventType, index) => (
                                                    <tr key={eventType.id}>
                                                        <td>
                                                            <strong title={eventType.description} style={{ fontWeight: 600 }}>{eventType.displayName}</strong> - {eventType.source}
                                                        </td>
                                                        <td className="text-center">
                                                            <Form.Check
                                                                type="switch"
                                                                checked={eventType.delayedEmailDelivery}
                                                                onChange={() => toggleSubscribeByEmail(eventType)}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <Form.Check
                                                                type="switch"
                                                                checked={eventType.imidiateEmailDelivery}
                                                                onChange={() => toggleSubscribeByEmailImidiate(eventType)}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <Form.Check
                                                                type="switch"
                                                                title="Legg inn et mobilnummer i profilen din for å kunne motta varsler på sms"
                                                                checked={eventType.smsDelivery}
                                                                onChange={() => toggleSubscribeBySms(eventType)}
                                                            />
                                                        </td>
                                                        <td className="fitContent">
                                                            <Form.Check
                                                                checked={selectedEventTypeIds.some(id => id === eventType.id)}
                                                                onChange={() => toggleEventType(eventType.id)}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    }
                </Card.Body>
            </Card>

            {profile && isAdminModus &&
                <Card className="mt-3 mb-3">
                    <Card.Body>
                        <Card.Title>Auditlog</Card.Title>
                        <PersonalSubscriberAuditLog personalSubscriberId={profile.id} reload={reloadAuditlog} />
                    </Card.Body>
                </Card>
            }

            {profile && isAdminModus &&
                <Card className="mt-3 mb-3">
                    <Card.Body>
                        <Card.Title>Eventlog</Card.Title>
                        <PersonalSubscriberEventLog personalSubscriberId={profile.id} />
                    </Card.Body>
                </Card>
            }

            <SubscribeModal profile={profile} show={showAddEventModal} onClose={handleCloseAddModal} />
            <ProfileModal show={showProfileModal} data={profileModalData} onClose={handleProfileModalClose} />
        </PageWrapper>
    );
};

export default VarslingsInnstillinger;