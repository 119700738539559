import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Spinner = styled.div`
  width: var(--bs-spinner-width, 2rem);
  height: var(--bs-spinner-height, 2rem);
  border: var(--bs-spinner-border-width, 0.25em) solid #f3f3f3;
  border-top-color: #0075db !important; 
  border-radius: 50%;
  animation: ${spin} var(--bs-spinner-animation-speed, 0.75s) linear infinite;
`;

interface CommonLoaderProps {
  text?: string;
  color?: string;
}

const CommonLoader: React.FC<CommonLoaderProps> = ({ text = 'Laster...', color }) => {
  return (
    <LoaderContainer>
      <Spinner style={color ? { borderTopColor: color } : {}} />
      <span style={{ fontSize: '16px', paddingTop: '1em', color: 'grey' }}>{text}</span>
    </LoaderContainer>
  );
};

export default CommonLoader;
