import { IconButton } from "@tradesolution/iceberg-ui-react";
import SupernovaApi from "api/SupernovaApi";
import { FailedAttempt, Webhook, WebhookLogEntry, WebhookRequest } from "api/SupernovaApi/models";
import PageWrapper from "components/PageWrapper";
import PrettyPrintJsonModal from "components/PrettyPrintJsonModal";
import { format } from "date-fns";
import useToaster from "framework/Toaster/useToaster";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ResendManyModal from "./ResendManyModal";
import "../index.scss";
import FilterWebhookEventLog from "./FilterWebhookEventlog";
import IcebergBadge from "components/IcebergBadge";
import { useDebounce } from "utils/hooks/useDebounce";
import CommonLoader from "components/CommonLoader";


const EventLog = () => {
    const take = 40;
    const [page, setPage] = useState(1);
    let { id } = useParams();
    const toaster = useToaster();
    const [tjeneste, setTjeneste] = useState<string | undefined>();
    const [kunUleverte, setKunUleverte] = useState<boolean>(false);
    const [showResendManyModal, setShowResendManyModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean | null>(null);
    const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
    const [searchableText, setSearchableText] = useState<string>("");
    const debouncedQ = useDebounce<string>(searchableText, 500);
    const [startDate, setStartDate] = useState<Date | null>(null);

    const getLog = async () => {
        setIsLoading(page === 1);
        setIsFetchingMore(page > 1);
        try {
            const reponse = await SupernovaApi.webhooks.getLog(id, kunUleverte, startDate, tjeneste, debouncedQ, take * page);
            if (page === 1) {
                setMessages(reponse);
            } else {
                setMessages(prev => [...prev, ...reponse.slice(prev.length)]);
            }
            setHasMore(reponse.length >= (take * page));
        } catch (error) {
            console.error('Feil ved innlasting av  meldinger:', error);
        } finally {
            setIsLoading(false);
            setIsFetchingMore(false);
        }
    }

    const loadWebhook = async () => {
        const response = await SupernovaApi.webhooks.get(id);
        setWebhook(response);
    }

    const handleModalClose = (): void => {
        setShowResendManyModal(false);
        getLog();
    }

    const [webhook, setWebhook] = useState<Webhook | undefined>(undefined);

    const [messages, setMessages] = useState<WebhookLogEntry[]>([]);

    useEffect(() => {
        loadWebhook();
    }, [id]);

    useEffect(() => {
        getLog();
    }, [id, kunUleverte, tjeneste, page, debouncedQ, startDate]);

    useEffect(() => {
        setPage(1);
    }, [kunUleverte, tjeneste, debouncedQ, startDate])

    const resend = async (message: WebhookLogEntry) => {
        const apiResult = await SupernovaApi.webhooks.resend(id, message.eventId);
        message.reSentAt = new Date();
        if (apiResult.success) {
            toaster.success("Meldingen er resendt");
        } else {
            toaster.success(`Kunne ikke resende, ${apiResult.error}`);
        }
    };

    const handleTjenesteChanged = (source: string): void => {
        setTjeneste(source);
    }


    const [jsonObject, setJsonObject] = useState<WebhookRequest | FailedAttempt[] | undefined>(undefined);
    const showPayload = (logEntry: WebhookLogEntry): void => {
        setJsonObject(logEntry.webhookRequest);
    }

    const showErrors = (logEntry: WebhookLogEntry): void => {
        setJsonObject(logEntry.failedAttempts);
    }

    const [showFilter, setShowFilter] = useState(false);
    const [activeFilters, setActiveFilters] = useState<{ label: string, removeAction: () => void }[]>([]);
    const formatDate = (date) => {
        return date.toLocaleString('no-NO', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).replace(/,/g, '');
    };
    useEffect(() => {
        let newFilters: { label: string, removeAction: () => void }[] = [];
        if (tjeneste) {
            newFilters.push({ label: `${tjeneste}`, removeAction: () => setTjeneste("") });
        }
        if (startDate) {
            newFilters.push({ label: formatDate(startDate), removeAction: () => setStartDate(null) });
        }
        if (kunUleverte) {
            newFilters.push({ label: 'Kun Uleverte', removeAction: () => setKunUleverte(false) });
        }
        setActiveFilters(newFilters);
    }, [tjeneste, startDate, kunUleverte]);

    const removeFilter = (filter: string) => {
        const filterObject = activeFilters.find(f => f.label === filter);
        if (filterObject) {
            filterObject.removeAction();
        }
        setActiveFilters(prevFilters => prevFilters.filter(f => f.label !== filter));
    };

    return (
        <PageWrapper title={`Logg for ${webhook?.name ?? ""}`}>
            <>
                <Row className="mb-3">
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="Søk på event eller oppsummeringsmelding"
                            value={searchableText}
                            onChange={(e) => setSearchableText(e.target.value)}
                        />
                    </Col>
                    <Col>
                        <IconButton
                        variant="outline-primary"
                        icon="filter"
                        onClick={() => setShowFilter(!showFilter)}
                    >
                        Filter
                    </IconButton>
                        <FilterWebhookEventLog
                            tjeneste={tjeneste}
                            handleTjenesteChanged={handleTjenesteChanged}
                            setKunUleverte={setKunUleverte}
                            kunUleverte={kunUleverte}
                            setStartDate={setStartDate}
                            startDate={startDate}
                            show={showFilter}
                            onClose={() => setShowFilter(false)}
                        />
                    </Col>
                    <Col className="text-end">
                        <span><IconButton icon="redo" size="sm" variant="outline-primary" onClick={() => setShowResendManyModal(true)}>Resend flere</IconButton></span>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        {activeFilters.length > 0 && (
                            <div
                                onClick={() => {
                                    handleTjenesteChanged('');
                                    setKunUleverte(false);
                                    setStartDate(null);
                                }}
                                style={{ cursor: 'pointer', display: 'inline-flex', verticalAlign: 'middle' }}
                            >
                                <IcebergBadge variant="primary" style={{ verticalAlign: 'middle' }}>
                                    <span style={{ verticalAlign: 'middle' }}>Nullstill</span>
                                    <Button
                                        size="sm"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleTjenesteChanged('');
                                            setKunUleverte(false);
                                        }}
                                        style={{
                                            marginLeft: '4px',
                                            padding: '0',
                                            verticalAlign: 'middle',
                                            paddingTop: '2px',
                                            paddingBottom: '2px',
                                            backgroundColor: 'transparent',
                                            borderColor: 'transparent',
                                            color: 'inherit'
                                        }}
                                    >
                                        x
                                    </Button>
                                </IcebergBadge>
                            </div>
                        )}
                        {activeFilters.map((filter, index) => (
                            <div
                                key={index}
                                onClick={() => removeFilter(filter.label)}
                                style={{ cursor: 'pointer', display: 'inline-flex', verticalAlign: 'middle', margin: '2px' }}
                            >
                                <IcebergBadge variant="light" style={{ verticalAlign: 'middle' }}>
                                    <span style={{ verticalAlign: 'middle' }}>
                                        {filter.label}
                                    </span>
                                    <Button
                                        size="sm"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeFilter(filter.label);
                                        }}
                                        style={{
                                            marginLeft: '4px',
                                            padding: '0',
                                            verticalAlign: 'middle',
                                            paddingTop: '2px',
                                            paddingBottom: '2px',
                                            backgroundColor: 'transparent',
                                            borderColor: 'transparent',
                                            color: 'inherit'
                                        }}
                                    >
                                        x
                                    </Button>
                                </IcebergBadge>
                            </div>
                        ))}
                    </Col>
                </Row>

                {isLoading ? null : (
                    <>
                        {messages.length === 0 && (<p>Ingen meldinger hittil!</p>)}
                        {messages.length > 0 && (

                            <Row>
                                <Col>
                                    <Table className="tsTable" hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Levert</th>
                                                <th>Hendelse</th>
                                                <th className="text-center">Varighet</th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {messages.map((message, index) => (
                                                <tr key={message.eventId} className=
                                                    {message.failedAttempts.length > 0 && message.reSentAt === null ?
                                                        "table-danger" : ""}>
                                                    <td>
                                                        {message.initialDeliveryAttemptedAt ? format(
                                                            message.initialDeliveryAttemptedAt,
                                                            'dd.MM.yyyy HH:mm:ss'
                                                        ) : ''}
                                                    </td>
                                                    <td>
                                                        <strong style={{ fontWeight: 600 }}>{message.event}</strong> - {message.source}<br />
                                                        {message.webhookRequest?.text}
                                                    </td>
                                                    <td className="text-center">
                                                        {message.durationInMilliseconds} ms
                                                    </td>
                                                    <td className="text-center">
                                                        <Button variant="outline-primary" size="sm" onClick={() => showPayload(message)}>Detaljer</Button>
                                                    </td>
                                                    <td className="text-center">
                                                        {message.failedAttempts.length > 0 &&
                                                            <Button variant="outline-primary" size="sm" onClick={() => showErrors(message)}>Vis feil</Button>}
                                                    </td>
                                                    <td className="fitContent">
                                                        {message.reSentAt === null && message.failedAttempts.length > 0 && (
                                                            <IconButton variant="outline-primary" icon="redo" onClick={() => resend(message)}>Resend</IconButton>
                                                        )}
                                                        {message.reSentAt && (
                                                            <div>Resendt {format(message.reSentAt, 'dd.MM.yyyy')}</div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        )}
                    </>
                )}
                <Row className="p-3">
                    <Col style={{ textAlign: 'center' }}>
                        {isLoading && !isFetchingMore && (
                            <CommonLoader text="Henter..." />
                        )}
                        {isFetchingMore && (
                            <CommonLoader text="Henter mer..."/>
                        )}
                        {!isLoading && !isFetchingMore && hasMore && (
                            <button type="button" className="btn btn-outline-primary"
                                onClick={() => setPage(page + 1)}>Last flere</button>
                        )}
                    </Col>
                </Row>
                <PrettyPrintJsonModal
                    object={jsonObject}
                    show={jsonObject !== undefined}
                    onClose={() => setJsonObject(undefined)}
                />
            </>
            <ResendManyModal show={showResendManyModal} webhookId={webhook?.id} onClose={handleModalClose} />
        </PageWrapper>
    );
}

export default EventLog;