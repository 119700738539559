import { useState, useEffect } from 'react';

export interface IFormData<TRequest, TResponse> {
    handleChange: (name: string, value: any) => void;
    handleSubmit: (e: any) => Promise<TResponse | undefined>;
    values: TRequest;
    errors: any;
    touched: any;
    submitDisabled: boolean;
    hasErrors: boolean;
    loading: boolean;
    updateValues: (updates: TRequest, taget?: string) => void;
    resetForm: (v: TRequest | undefined) => void;
    resetValue: (key: string) => void;
    resetValues: (key: string[]) => void;
}

function useForm<TRequest, TResponse>(
    initialState: TRequest,
    validate: (values: TRequest) => object,
    onSubmit: (values: TRequest) => Promise<TResponse>,
): IFormData<TRequest, TResponse> {
    const [initialValues] = useState({ ...initialState });
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({} as any);
    const [touched, setTouched] = useState({} as any);
    const [loading, setLoading] = useState(false);

    const anyValuesChanged = Object.keys(touched).length > 0;
    const hasErrors = Object.keys(errors).length > 0;
    const submitDisabled = !anyValuesChanged || hasErrors || loading;
    // validate any time values changes (on every key press)
    useEffect(() => {
            setErrors(validate(values));
    }, [values]);

    const handleChange = (name: string, value: any) => {
        setValues({ ...values, [name]: value });
        setTouched({ ...touched, [name]: true });
    };


    const updateValues = (updatedValues: any, target?: string) => {
        if (target && updatedValues[target]) { // for objekt props med children, gjelder for adresser
            for (const [key, value] of Object.entries(updatedValues[target])) {
                if (values[key] !== value) {
                    setTouched((prev: any) => ({
                        ...prev,
                        [key]: true,
                    }));
                }
            }
        }

        for (const [key, value] of Object.entries(updatedValues)) {
            if (values[key] !== value) {
                // pga at vi ofte gir inputfelter eller defaultverdier slik:
                // enhet.navn || '', så vil denne (hvis man bruker initialValues[key])
                // tro at alle de har endret seg når denne funksjonen kjører..
                setTouched((prev: any) => ({
                    ...prev,
                    [key]: true,
                }));
                // endret over fra: touched[key] = true;
                // uvisst om nødvendig, men ble til under testing for å påse at det kun er endret verdi som får true...
            }
        }
        setValues(updatedValues);
    };

    // always returns a Promise because of the async keyword
    const handleSubmit = async (e: any) => {
        if (e) {
            e.preventDefault();
        }

        setErrors(validate(values));
        if (!hasErrors) {
            setLoading(true);
            const response = await onSubmit(values);
            setLoading(false);
            return response;
        }
    };

    const resetForm = (vals: TRequest | undefined) => {
        if (vals) {
            setValues(vals);
        } else {
            setValues(initialValues);
        }
        setErrors({});
        setTouched({});
    };

    const resetValue = (key: string) => {
        /*     if (errors.key && touched.key) {
          setTouched({ ...touched, [key]: false });
          setErrors({ ...errors, [key]: undefined });
        } */
        const errCopy = { ...errors };
        delete errCopy[key];
        setErrors(errCopy);

        const touchedCopy = { ...touched };
        delete touchedCopy[key];
        setTouched(touchedCopy);
    };

    const resetValues = (keys: string[]) => {
        const errCopy = {
            ...errors,
        };
        const touchedCopy = {
            ...touched,
        };
        for (const key of keys) {
            delete errCopy[key];

            delete touchedCopy[key];
        }
        setErrors(errCopy);
        setTouched(touchedCopy);
    };

    return {
        handleChange,
        handleSubmit,
        values,
        updateValues,
        errors,
        touched,
        loading,
        hasErrors,
        submitDisabled,
        resetForm,
        resetValue,
        resetValues,
    };
}

export default useForm;
