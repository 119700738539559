import { LogEntry } from "api/SupernovaApi/models";
import PrettyPrintJsonModal from "components/PrettyPrintJsonModal";
import { format } from "date-fns";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";

interface Props {
    logEntries: LogEntry[];
}

const EventLogTable = (props: Props) => {
    const [jsonObject, setJsonObject] = useState<any | undefined>(undefined);
    const showDetails = (logEntry: LogEntry): void => {
        setJsonObject(logEntry.recievedEvent);
    }

    const showErrors = (logEntry: LogEntry): void => {
        setJsonObject(logEntry.failedAttempts);
    }

    const showOk = (logEntry: LogEntry): void => {
        setJsonObject(logEntry.successfullDeliveries);
    }

    return (
        <>
            <Table className="tsTable" hover responsive>
                <thead>
                    <tr>
                        <th>Levert</th>
                        <th>Hendelse</th>
                        <th>Antall feil</th>
                        <th>Antall ok</th>
                        <th>Varighet (s)</th>
                        <th>Tid i kø (s)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.logEntries.map((x, i) => (
                        <tr key={x.eventId} className={x.numberOfFailures > 0 ? 'table-danger' : ''}>
                            <td>{format(x.deliveryStart, 'dd.MM.yyyy HH:mm:ss')}</td>
                            <td>
                                {x.recievedEvent?.userInterfaceUrl ? (
                                    <a
                                        href={x.recievedEvent.userInterfaceUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <strong style={{ fontWeight: 600 }}>{x.eventTypeName} </strong> - {x.source}
                                    </a>
                                ) : (
                                        <>
                                            <strong style={{ fontWeight: 600 }}>{x.eventTypeName} </strong> - {x.source}
                                        </>
                                )}
                                <br />
                                {x.recievedEvent?.displayMessage}
                            </td>
                            <td>
                                {x.failedAttempts.length > 0 &&
                                    <Button variant="link" size="sm" onClick={() => showErrors(x)}>{x.failedAttempts.length}</Button>
                                }
                            </td>
                            <td>
                                {(x.successfullDeliveries && x.successfullDeliveries.length > 0) &&
                                    <Button variant="link" size="sm" onClick={() => showOk(x)}>{x.successfullDeliveries.length}</Button>
                                }
                            </td>
                            <td>{x.durationInMilliseconds / 1000}</td>
                            <td>{x.waitBeforeProcessingBeganInSec}</td>
                            <td>
                                <Button variant="outline-primary" size="sm" onClick={() => showDetails(x)}>Detaljer</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <PrettyPrintJsonModal
                object={jsonObject}
                show={jsonObject !== undefined}
                onClose={() => setJsonObject(undefined)}
            />
        </>
    )
};

export default EventLogTable;