import SupernovaApi from "api/SupernovaApi";
import { SourceItem } from "api/SupernovaApi/models";
import IcebergBadge from "components/IcebergBadge";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

interface Props {
    selectedSource?: string;
    onChange: (source: string) => void;
    isInvalid?: boolean;
}

const TjenestePicker = (props: Props) => {

    const [sources, setSources] = useState<SourceItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const load = async () => {
            setIsLoading(true);
            const s = await SupernovaApi.getMySources();

            // Ensuring uniqueness based on claimName
            const uniqueSources = s.reduce((acc, current) => {
                const x = acc.find(item => item.claimName === current.claimName);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);

            setSources(uniqueSources.sort((a, b) => a.name.localeCompare(b.name)));
            setIsLoading(false);
        };
        load();
    }, [])

    return (
        <>
            <Form.Select
                value={props.selectedSource || ""}
                onChange={e => props.onChange(e.target.value)}
                isInvalid={props.isInvalid}
            >
                <option value="">Velg tjeneste</option>
                {sources.map(x => (
                    <option key={x.claimName}>{x.name}</option>
                ))}
            </Form.Select>
            {isLoading ? (
                <div>Laster tilgjengelige kilder...</div>
            ) : (
                sources.length === 0 && (
                        <IcebergBadge variant="warning" style={{ width: "100%", marginTop: "10px" }}>
                            <div>Du har for øyeblikket ikke tilgang til noen tjenester.</div>
                            <div>Vennligst kontakt support hvis du ønsker å abonnere på hendelser fra en tjeneste.</div>
                        </IcebergBadge>
                )
            )}
        </>
    )
};

export default TjenestePicker;